export const stays = [
  {
    id: 'Les-fondamentaux-du-management',
    title: "Les fondamentaux du management",
    priceActual: "1490 €",
    category: "aze",
    categoryText: "aze aze",
    alternance: "1",
    description:
      "Cette formation vise à fournir aux participants les connaissances essentielles et les compétences fondamentales nécessaires pour devenir des gestionnaires efficaces. Les participants apprendront à comprendre et à mettre en pratique les principes du management, à créer un cadre de travail favorable à la réussite, à développer des attitudes positives pour leur prise de fonction, à mobiliser les énergies de leur équipe, à établir une communication efficace, à soutenir les performances et les compétences, à surmonter les difficultés, à mettre en place un tableau de bord pour le suivi des objectifs, et à valoriser les communications efficaces. La formation sera dispensée à travers une combinaison de cours magistraux, d'exercices pratiques, d'études de cas, de jeux de rôle et de discussions en groupe. Les participants auront l'occasion de mettre en pratique les concepts appris et de recevoir des retours d'experts en management.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Les fondamentaux du management.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Comprendre ce qu’est le management",
        content:
          "Vous serez en mesure de définir et de comprendre les principes fondamentaux du management, y compris les rôles et les responsabilités d'un gestionnaire.",
        color: "#E0EEF7",
      },
      {
        title: "Création d'un Cadre de Travail ",
        content: "Vous saurez établir un cadre de travail solide en définissant des normes, des procédures et des attentes claires pour leur équipe, favorisant ainsi un environnement de travail productif.",
        color: "#D3EEDC",
      },
      {
        title: "Attitudes de Leadership",
        content:
          "Vous developperez des attitudes positives de leadership, y compris la confiance en soi, l'empathie, la résolution de problèmes et la prise de décision efficace.",
        color: "#fff4d9",
      },
      {
        title: "Gestion des Conflits",
        content:
          "Vous apprendrez à gérer les conflits de manière constructive, à résoudre les problèmes et à maintenir un climat de travail harmonieux.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Management-d-équipe',
    title: "Management d’ équipe",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    alternance: "0",
    description:
      "Cette formation vise à fournir aux participants les connaissances essentielles et les compétences fondamentales nécessaires pour devenir des gestionnaires efficaces. Les participants apprendront à comprendre et à mettre en pratique les principes du management, à créer un cadre de travail favorable à la réussite, à développer des attitudes positives pour leur prise de fonction, à mobiliser les énergies de leur équipe, à établir une communication efficace, à soutenir les performances et les compétences, à surmonter les difficultés, à mettre en place un tableau de bord pour le suivi des objectifs, et à valoriser les communications efficaces. La formation sera dispensée à travers une combinaison de cours magistraux, d'exercices pratiques, d'études de cas, de jeux de rôle et de discussions en groupe. Les participants auront l'occasion de mettre en pratique les concepts appris et de recevoir des retours d'experts en management.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Management d’ équipe.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Communication efficace",
        content:
          "Vous acquerrez des techniques de communication avancées pour dialoguer efficacement avec votre équipe, favorisant un environnement de travail transparent et ouvert. Cela inclut l'art de l'écoute active et la capacité de communiquer vos attentes de manière claire et concise.",
        color: "#E0EEF7",
      },
      {
        title: "Leadership inspirant",
        content: "Vous apprendrez à forger votre propre style de leadership qui inspire et motive. Nous vous enseignerons comment devenir un leader visionnaire, capable de partager une vision claire et d'encourager chaque membre de l'équipe à réaliser son plein potentiel.",
        color: "#D3EEDC",
      },
      {
        title: "Gestion des conflits",
        content:
          "Vous saurez identifier et résoudre les conflits au sein de l'équipe de manière constructive. Nous vous doterons d'outils pour gérer les désaccords, faciliter le dialogue et négocier des solutions qui satisfont toutes les parties.",
        color: "#fff4d9",
      },
      {
        title: "Prise de décision stratégique",
        content:
          "Vous développerez la capacité de prendre des décisions réfléchies qui tiennent compte à la fois de l'analyse de données et de l'intuition humaine. Apprenez à équilibrer le risque et l'innovation pour conduire votre équipe à travers des défis complexes et changeants.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Gestion-des-conflits',
    title: "Gestion des conflits",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description:
      "La formation Management d'équipe est conçue pour équiper les managers actuels et en devenir avec les compétences essentielles nécessaires pour diriger avec efficacité et résilience. À travers un parcours interactif et approfondi, les participants seront immergés dans les stratégies pratiques de leadership, en se concentrant particulièrement sur la gestion des conflits, la communication efficace, et la création d'un milieu de travail collaboratif. Cette formation vise à transformer les défis relationnels en opportunités de croissance pour l'équipe et l'organisation.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Gestion des conflits.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Gestion des conflits",
        content:
          " Vous apprendrez À reconnaître et gérer les signes avant-coureurs de conflits au sein de l'équipe, en employant des techniques de médiation et de négociation pour résoudre les désaccords de manière constructive et maintenir la cohésion de l'équipe.",
        color: "#E0EEF7",
      },
      {
        title: "Relationnel intelligent",
        content: "Vous acquerrez des méthodes pour enrichir votre intelligence relationnelle, ce qui vous permettra de forger des liens solides avec vos collaborateurs, en cultivant un climat de confiance et en motivant chacun à contribuer au meilleur de ses capacités.",
        color: "#D3EEDC",
      },
      {
        title: "Résolution de conflits ouverts ",
        content:
          "Vous saurez approcher les situations délicates avec confiance et utiliser des approches structurées pour transformer les conflits ouverts en dialogues constructifs, tout en préservant l'intégrité et le moral de l'équipe.",
        color: "#fff4d9",
      },
      {
        title: "Environnement collaboratif",
        content:
          "Vous serez capable de créer et de maintenir un environnement de travail qui encourage la collaboration et le dialogue ouvert, en instaurant des pratiques qui favorisent l'innovation collective et la résolution de problèmes en équipe.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Gestion-de-projet',
    title: "Gestion de projet",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Gestion de projet est spécialement conçue pour vous initier aux principes fondamentaux du management de projet. Elle s'adresse à ceux qui souhaitent maîtriser les multiples aspects de la gestion de projet, y compris la performance, les coûts, les délais et la gestion des risques. Les modules sont élaborés pour vous aider à comprendre votre rôle essentiel au sein d'un projet et à maximiser votre valeur ajoutée. Par ailleurs, cette formation met l'accent sur l'importance du travail d'équipe et sur les compétences nécessaires pour diriger un projet avec succès jusqu'à son achèvement.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Gestion de projet.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Fondations du management de projet",
        content:
          "Vous découvrirez les principes clés du management de projet, y compris le cycle de vie d'un projet, la définition des objectifs, et les méthodes de planification. Vous comprendrez comment les fondations solides du management de projet peuvent influencer positivement le résultat final.",
        color: "#E0EEF7",
      },
      {
        title: "Intégration des composantes clés",
        content: "Vous intégrerez une compréhension approfondie de la façon de balancer et d'optimiser les performances, les coûts, les délais et les risques pour mener vos projets à terme avec succès. Vous apprendrez à utiliser des outils et des techniques pour évaluer et contrôler ces variables tout au long du projet.",
        color: "#D3EEDC",
      },
      {
        title: "Rôle et valeur ajoutée",
        content:
          "Vous identifierez votre position unique au sein des projets et comment votre contribution s'intègre dans une stratégie plus large. Vous renforcerez votre capacité à apporter une valeur ajoutée significative à chaque phase du projet, renforçant ainsi votre rôle de chef de projet compétent.",
        color: "#fff4d9",
      },
      {
        title: "Travail en équipe projet",
        content:
          "Vous apprendrez à collaborer efficacement au sein d'une équipe projet. Vous acquerrez des compétences en communication et en résolution de conflits qui sont essentielles pour travailler en harmonie avec des collaborateurs aux compétences diverses.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Management-et-politique-publique',
    title: "Management et politique publique",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Management et politique publique s'adresse aux professionnels du secteur public désireux d'affiner leurs compétences managériales dans le contexte spécifique des missions de service public. En combinant théorie et pratique, cette formation propose une plongée approfondie dans les stratégies de leadership adaptées aux défis contemporains du management public. Les participants seront guidés à travers un éventail de compétences essentielles, depuis la motivation des équipes jusqu'à la gestion efficace du changement organisationnel.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Management et politique publique.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Stratégies de motivation",
        content:
          "ous saurez personnaliser votre approche managériale pour répondre aux besoins individuels de motivation de vos collaborateurs, en appliquant des techniques de management situationnel pour renforcer leur performance et leur satisfaction au travail.",
        color: "#E0EEF7",
      },
      {
        title: "Management de mission",
        content: "Vous apprendrez à adopter la posture managériale adaptée à la conduite de missions spécifiques, en identifiant les leviers de motivation pertinents pour chaque situation et pour chaque collaborateur, afin de maximiser l'efficacité et l'engagement.",
        color: "#D3EEDC",
      },
      {
        title: "Leadership et changement",
        content:
          "Vous acquerrez des compétences pour appréhender et exercer le leadership de manière efficace, en gérant le changement organisationnel avec assurance et en animant des réunions qui stimulent la participation et l'innovation.",
        color: "#fff4d9",
      },
      {
        title: "Résolution de conflits",
        content:
          "Vous serez capable d'identifier, de comprendre et de résoudre les conflits ouverts, en adoptant une attitude constructive et en agissant en tant que médiateur impartial pour faciliter le dialogue et la recherche de solutions collaboratives.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Management-d-équipe-dans-un-contexte-de-changement',
    title: "Management d’équipe dans un contexte de changement",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Management d'équipe dans un contexte de changement est spécialement conçue pour préparer les managers à piloter leurs équipes à travers les défis et les opportunités présentés par les périodes de transition. Les participants seront initiés à des techniques de leadership éprouvées pour inspirer, communiquer et engager leurs équipes, même lorsque l'entreprise traverse des phases de changement significatif.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Management d’équipe dans un contexte de changement.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Leadership adaptatif",
        content:
          "Vous serez capable d'ajuster votre style de management pour répondre aux besoins changeants de votre équipe, en appliquant des stratégies flexibles pour guider, soutenir et motiver vos collaborateurs à travers les phases de transition.",
        color: "#E0EEF7",
      },
      {
        title: "Motivation d'équipe",
        content: "Vous apprendrez des méthodes innovantes pour maintenir un niveau élevé de motivation au sein de votre équipe, en identifiant et en exploitant les leviers de motivation individuels et collectifs, pour assurer une performance optimale malgré les incertitudes.",
        color: "#D3EEDC",
      },
      {
        title: "Communication efficace",
        content:
          "Vous acquerrez les compétences pour communiquer clairement et efficacement avec vos collaborateurs, en assurant que les objectifs, les attentes et les changements soient compris et acceptés par tous les membres de l'équipe.",
        color: "#fff4d9",
      },
      {
        title: "Animation de réunion",
        content:
          "Vous saurez organiser et animer des réunions engageantes et productives, en maîtrisant des techniques pour favoriser la participation active, le brainstorming constructif et la prise de décisions collaborative.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Communication-de-crises',
    title: "Communication de crises",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Communication de crises est spécialement élaborée pour préparer les professionnels à gérer efficacement la communication en période de crise. En mettant l'accent sur la préparation, l'adaptation et la résilience, cette formation vise à doter les participants des compétences nécessaires pour naviguer dans les périodes tumultueuses, en assurant une communication claire et réfléchie qui soutient à la fois les individus et l'organisation dans son ensemble.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Communication de crises.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Adaptation au poste de travail",
        content:
          "Vous apprendrez à communiquer efficacement lors des périodes de changement, en aidant les travailleurs à s'adapter à leur poste et aux évolutions de leur environnement professionnel, tout en veillant à leur bien-être et à leur efficacité opérationnelle.",
        color: "#E0EEF7",
      },
      {
        title: "Développement des compétences",
        content: "Vous acquerrez les outils pour identifier et cultiver les compétences essentielles des travailleurs, en lien direct ou non avec leur poste actuel. Cela comprend la formation continue, le coaching et l'utilisation de feedback constructif pour favoriser la croissance professionnelle.",
        color: "#D3EEDC",
      },
      {
        title: "Qualification professionnelle",
        content:
          "Vous saurez guider les travailleurs dans le processus d'acquisition de qualifications plus élevées grâce à une communication ciblée et stratégique, en les motivant à poursuivre leur développement professionnel et en reconnaissant leur progression.",
        color: "#fff4d9",
      },
      {
        title: "Gestion de la communication en crise ",
        content:
          "Vous serez capable de développer un plan de communication de crise robuste, d'anticiper les scénarios de crise potentiels et de réagir de manière proactive pour minimiser l'impact sur l'entreprise et ses employés.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Parole-en-public',
    title: "Parole en public",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Prise de parole en public est spécifiquement conçue pour ceux qui souhaitent maîtriser l'art de communiquer efficacement en public. Que vous soyez appelé à présenter devant un petit groupe ou une grande assemblée, cette formation vous dotera des outils et techniques nécessaires pour captiver votre auditoire. En abordant des méthodes éprouvées et des exercices pratiques, les participants dépasseront leur angoisse, peaufineront leur discours et apprendront à utiliser leur présence corporelle pour renforcer leur message.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Parole en public.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise de l'angoisse",
        content: "Vous apprendrez à surmonter l'anxiété liée à la prise de parole grâce à des techniques de relaxation et de préparation mentale, vous permettant de vous présenter devant un auditoire avec confiance et sérénité.",
        color: "#E0EEF7",
      },
      {
        title: "Perfectionnement du discours",
        content: "Vous acquerrez les compétences pour structurer vos interventions de manière cohérente et impactante, en affinant votre capacité à construire des arguments clairs et persuasifs adaptés à votre public.",
        color: "#D3EEDC",
      },
      {
        title: "Communication non-verbale",
        content: "Vous saurez intégrer les règles corporelles incontournables dans votre présentation, en utilisant le langage du corps pour appuyer votre discours et établir une connexion authentique avec l'auditoire.",
        color: "#fff4d9",
      },
      {
        title: "Stratégies d'engagement",
        content: "Vous serez capable d'incorporer des éléments tels que l'humour et le silence stratégique dans vos prises de parole, utilisant ces atouts pour captiver l'attention, souligner des points importants et laisser une impression mémorable.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Introduction-au-Marketing-digital',
    title: "Introduction au Marketing digital",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "L'introduction au marketing digital est une formation essentielle pour quiconque cherche à naviguer dans le paysage complexe et en constante évolution du webmarketing. Elle est conçue pour fournir une compréhension solide des principes fondamentaux du marketing en ligne, tout en offrant des stratégies pratiques pour attirer et engager un public en ligne. Les participants seront guidés à travers les différentes facettes du marketing digital, de l'optimisation des moteurs de recherche à la publicité payante, et apprendront à mesurer et à analyser l'efficacité de leurs campagnes.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Introduction au Marketing digital.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Fondamentaux du Webmarketing",
        content: "Vous comprendrez les principes clés du marketing digital, y compris le SEO, le content marketing, et les médias sociaux, pour poser les bases d'une stratégie webmarketing efficace et intégrée.",
        color: "#E0EEF7",
      },
      {
        title: "Stratégie Webmarketing",
        content: "Vous maîtriserez les étapes pour développer et mettre en œuvre une stratégie webmarketing cohérente et alignée avec les objectifs globaux de votre entreprise, en tenant compte des dernières tendances et outils disponibles.",
        color: "#D3EEDC",
      },
      {
        title: "Adaptation au Marketing Web",
        content: "Vous acquerrez des techniques pour augmenter la visibilité de votre site web et de votre contenu en ligne, en utilisant des méthodes éprouvées pour améliorer votre classement dans les résultats de recherche et capturer l'attention des utilisateurs en ligne.",
        color: "#fff4d9",
      },
      {
        title: "Trafic Qualifié",
        content: "Vous saurez attirer des visiteurs qualifiés vers votre site web, ceux qui sont les plus susceptibles de se convertir en clients, en déployant des tactiques de ciblage précises et en créant des campagnes publicitaires efficaces.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Marketing-Digital-et-Growth-Hacking',
    title: "Marketing Digital et Growth Hacking",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Marketing Digital et Growth Hacking est spécialement conçue pour les professionnels désireux de maîtriser l'art et la science de la communication numérique moderne. Elle offre un aperçu approfondi des stratégies et des outils qui animent le marketing digital aujourd'hui, avec une attention particulière portée aux techniques de growth hacking qui peuvent propulser une entreprise vers de nouveaux sommets de réussite en ligne. Les participants exploreront les dernières tendances, apprendront à mesurer l'efficacité de leurs campagnes et à ajuster leurs stratégies pour optimiser la performance et le ROI.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Marketing Digital et Growth Hacking.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Principes de SEO et SEA",
        content: "Vous comprendrez les fondamentaux du référencement naturel et de la publicité payante, vous permettant de naviguer dans l'écosystème digital avec une compréhension claire de comment maximiser la visibilité et l'engagement en ligne.",
        color: "#E0EEF7",
      },
      {
        title: "Stratégies de Webmarketing",
        content: "Vous apprendrez à concevoir et à exécuter des campagnes de webmarketing et de growth hacking qui non seulement attirent l'attention mais aussi convertissent et fidélisent. Vous serez capable de déployer des stratégies créatives qui répondent aux objectifs spécifiques de votre entreprise.",
        color: "#D3EEDC",
      },
      {
        title: "Analyse et Optimisation avec Google Tools",
        content: "Vous saurez utiliser des outils comme Google Analytics, Google Search Console et Google Ads pour analyser les données de trafic, comprendre le comportement des utilisateurs, et affiner vos campagnes pour une efficacité maximale.",
        color: "#fff4d9",
      },
      {
        title: "Gestion de la Communication sur les Réseaux Sociaux",
        content: "Vous acquerrez la capacité d'évaluer l'impact des publications sur les réseaux sociaux et d'ajuster votre stratégie de contenu pour maximiser l'engagement et étendre votre portée, en comprenant ce qui résonne le mieux avec votre audience.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Stratégie-de-Webmarketing',
    title: "Stratégie de Webmarketing",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Stratégie de Webmarketing est spécialement conçue pour les professionnels cherchant à maîtriser les aspects cruciaux du marketing digital. Dans un monde où la présence en ligne est déterminante, cette formation offre les outils et connaissances nécessaires pour concevoir et exécuter une stratégie webmarketing pertinente et efficace. Elle met l'accent sur l'innovation continue et le développement des compétences collectives, garantissant que votre équipe reste à la pointe des tendances du marketing digital.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Stratégie de Webmarketing.png",
    formateurs: [
      {
        title: " Rachid Tebboub",
        icon: "user",
      },
    ],
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Conception de stratégie webmarketing",
        content: "Vous apprendrez à identifier et à analyser les besoins spécifiques de votre marché cible, à définir des objectifs clairs et à élaborer une stratégie webmarketing complète qui intègre les différentes facettes du marketing digital, de l'optimisation pour les moteurs de recherche (SEO) à la publicité en ligne et au marketing de contenu.",
        color: "#E0EEF7",
      },
      {
        title: "Veille stratégique",
        content: "Vous acquerrez les compétences pour établir une veille stratégique efficace, vous permettant de rester informé des dernières évolutions du webmarketing et d'adapter votre stratégie en conséquence. Cela inclut l'utilisation d'outils de veille et l'analyse des données pour anticiper les tendances et les opportunités.",
        color: "#D3EEDC",
      },
      {
        title: "Développement des compétences en équipe",
        content: "Vous saurez mettre en place des méthodes pour encourager l'apprentissage continu et le développement des compétences de votre équipe. Vous serez en mesure de créer un environnement qui favorise le partage des connaissances et stimule l'innovation collective dans le domaine du webmarketing.",
        color: "#fff4d9",
      },
      {
        title: "Application pratique ",
        content: "Vous serez capable de traduire votre stratégie en actions concrètes, en utilisant des techniques avancées pour mesurer et analyser la performance de vos campagnes webmarketing. Cela vous permettra d'ajuster vos tactiques en temps réel et d'assurer un retour sur investissement optimal.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Compétences-digitales-et-Digcomp',
    title: "Compétences digitales et Digcomp",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Compétences digitales et Digcomp est spécifiquement structurée pour doter les professionnels des savoir-faire nécessaires à la navigation efficace dans le monde numérique d'aujourd'hui. Elle vise à renforcer la capacité des participants à opérer avec aisance dans un environnement en constante évolution, où la technologie joue un rôle central. Les participants seront amenés à développer une compréhension robuste et pratique des outils numériques, des logiciels, et des principes de la cybersécurité, tout en acquérant la confiance nécessaire pour gérer les problèmes informatiques courants.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Compétences digitales et Digcomp.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Recherche et actualisation d'informations",
        content: "Vous apprendrez à naviguer avec efficacité dans l'espace numérique pour rechercher et mettre à jour des informations, tout en évaluant leur pertinence et leur fiabilité pour une prise de décision éclairée dans le contexte professionnel.",
        color: "#E0EEF7",
      },
      {
        title: "Utilisation des logiciels et du matériel",
        content: "Vous acquerrez une connaissance approfondie de l'utilisation des outils logiciels les plus courants et du matériel informatique, vous permettant d'optimiser votre efficacité professionnelle et d'exploiter pleinement les ressources technologiques à votre disposition.",
        color: "#D3EEDC",
      },
      {
        title: "Critique et utilisation de contenus numériques",
        content: "Vous saurez utiliser de manière critique les applications et contenus numériques, reconnaissant leur impact et leur potentiel, tout en développant une capacité à questionner et évaluer les technologies que vous utilisez au quotidien.",
        color: "#fff4d9",
      },
      {
        title: "Création de contenu numérique et cybersécurité",
        content: "Vous serez capable de créer du contenu numérique pertinent et de qualité, tout en comprenant et en appliquant les fondamentaux de la cybersécurité pour protéger les données et les systèmes contre les menaces en ligne.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Créer-et-animer-un-site-WEB',
    title: "Créer et animer un site WEB",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Créer et animer un site WEB est spécialement conçue pour les entrepreneurs, les créateurs de contenu et les professionnels du marketing qui souhaitent étendre leur présence en ligne. Cette formation pratique vous guidera à travers les étapes de la conception, de la création et de la gestion d'un site web attrayant et fonctionnel. Vous apprendrez comment utiliser votre site web comme un puissant outil de communication pour améliorer votre notoriété, vendre vos produits et services, et construire une base de clients fidèles et engagés.",
    nbHeure: "35 Heures (5 jours)",
    src: "/images/Créer et animer un site WEB.png",
    options: [
      {
        title: "35 Heures (5 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Stratégies de visibilité",
        content: "Vous apprendrez à optimiser votre site pour les moteurs de recherche, à utiliser les médias sociaux pour améliorer votre visibilité en ligne, et à créer du contenu captivant qui attire et retient l'attention des visiteurs.",
        color: "#E0EEF7",
      },
      {
        title: "Commerce en ligne",
        content: "Vous acquerrez les compétences nécessaires pour configurer une boutique en ligne efficace, en comprenant les principes de l'e-commerce, les systèmes de paiement sécurisés, et les meilleures pratiques pour présenter vos produits et services de manière attrayante.",
        color: "#D3EEDC",
      },
      {
        title: "Acquisition de clients ",
        content: "Vous saurez mettre en œuvre des stratégies de marketing numérique pour attirer de nouveaux clients et prospects, en utilisant des outils tels que le marketing par courriel, la publicité en ligne et l'optimisation des taux de conversion.",
        color: "#fff4d9",
      },
      {
        title: "Fidélisation et croissance du C.A ",
        content: "Vous serez capable de mettre en place des techniques de fidélisation de la clientèle, telles que des programmes de fidélité, des newsletters personnalisées et des offres spéciales, pour encourager les clients à revenir et à augmenter votre chiffre d'affaires.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Référencement-naturel-SEO',
    title: "Référencement naturel SEO",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Référencement naturel SEO s'adresse aux professionnels des TPE/PME souhaitant maîtriser les rouages du SEO pour améliorer la visibilité de leur site internet. Le programme est pensé pour fournir un ensemble de compétences pratiques et théoriques, permettant aux participants de réaliser un audit SEO complet, d'identifier des axes d'amélioration et de mettre en œuvre des stratégies efficaces d'optimisation pour les moteurs de recherche. Les participants apprendront à naviguer dans l'écosystème complexe du SEO avec une approche structurée et adaptée aux ressources des petites et moyennes entreprises.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Référencement naturel SEO.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Audit SEO",
        content: "Vous apprendrez à réaliser un audit approfondi du référencement naturel de votre site internet, en identifiant les forces et les faiblesses actuelles, et en posant les fondations pour une stratégie d'amélioration ciblée.",
        color: "#E0EEF7",
      },
      {
        title: "Stratégie de mots-clés ",
        content: "Vous acquerrez les compétences pour identifier et sélectionner des mots-clés stratégiques qui attireront un trafic qualifié vers votre site internet, tout en apprenant à hiérarchiser ces mots-clés selon leur importance et leur pertinence pour votre activité.",
        color: "#D3EEDC",
      },
      {
        title: "Optimisation de contenu",
        content: "Vous saurez élaborer un planning éditorial aligné avec vos objectifs commerciaux et rédiger des contenus optimisés pour le SEO, qui non seulement captivent vos lecteurs mais améliorent également votre classement dans les résultats de recherche.",
        color: "#fff4d9",
      },
      {
        title: "Netlinking",
        content: "Vous serez capable de développer une stratégie de netlinking pour augmenter l'autorité de votre site internet, en apprenant à obtenir des liens de qualité provenant d'autres sites web, ce qui est crucial pour un référencement naturel réussi.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Créer-une-stratégie-de-communication-avec-les-Réseaux-et-ADS',
    title: "Créer une stratégie de communication avec les Réseaux et ADS",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Créer une stratégie de communication avec les Réseaux et ADS est une plongée stratégique et pratique dans le monde dynamique du marketing digital. Elle offre une vue d'ensemble complète sur l'écosystème des réseaux sociaux et des publicités en ligne, en mettant l'accent sur les compétences essentielles pour maîtriser ces plateformes dans un cadre professionnel. Les participants exploreront les différentes techniques de communication pour engager leur audience, optimiser leur présence en ligne et maximiser l'impact de leurs campagnes publicitaires.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Créer une stratégie de communication avec les Réseaux et ADS.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Vue d'ensemble des réseaux sociaux ",
        content: "Vous apprendrez à naviguer dans le paysage complexe des réseaux sociaux, en identifiant les plateformes les plus pertinentes pour votre marque et en comprenant les nuances de chaque canal pour optimiser votre stratégie de communication globale.",
        color: "#E0EEF7",
      },
      {
        title: "Maîtrise des médias sociaux",
        content: "Vous acquerrez des compétences avancées dans l'utilisation des médias sociaux pour le branding et le marketing, en apprenant à créer du contenu attrayant, à engager avec votre communauté, et à analyser les données pour améliorer continuellement vos efforts.",
        color: "#D3EEDC",
      },
      {
        title: "Utilisation professionnelle des réseaux principaux ",
        content: "Vous saurez exploiter les fonctionnalités spécifiques des principaux réseaux sociaux comme Facebook, Instagram, LinkedIn, et Twitter pour atteindre vos objectifs professionnels, en comprenant les meilleures pratiques pour chacun et en adaptant votre message à chaque plateforme.",
        color: "#fff4d9",
      },
      {
        title: "Techniques de communication sur les réseaux",
        content: "Vous serez capable de maîtriser les différentes techniques de communication digitale, y compris le storytelling, le content marketing, et les publicités payantes, pour créer des campagnes cohérentes et percutantes qui résonnent avec votre audience cible.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Prévention-Geste-&-Posture-au-travail',
    title: "Prévention Geste & Posture au travail",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Prévention Geste & Posture au travail est essentielle pour quiconque souhaite préserver son bien-être et celui de ses collaborateurs dans l'environnement professionnel. À travers ce programme, les participants découvriront des stratégies pratiques pour réduire les risques de blessures et augmenter la productivité en adoptant de bonnes postures et des gestes adaptés. Ce cours est particulièrement bénéfique pour ceux qui travaillent fréquemment devant un ordinateur ou qui sont impliqués dans des tâches impliquant le port de charges.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Prévention Geste & Posture au travail.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Fondamentaux de la législation",
        content: "Vous apprendrez les aspects clés de la législation en matière de santé et de sécurité au travail, vous dotant des connaissances nécessaires pour comprendre les droits et les responsabilités, tant des employés que des employeurs, dans la prévention des risques professionnels.",
        color: "#E0EEF7",
      },
      {
        title: "Bases de la physiologie et de la pathologie",
        content: "Vous acquerrez une compréhension des principes de la physiologie et de la pathologie liés au travail, ce qui vous permettra d'identifier les pratiques susceptibles de nuire à la santé et d'appliquer des principes ergonomiques pour promouvoir un environnement de travail sain.",
        color: "#D3EEDC",
      },
      {
        title: "Pratiques de travail optimales devant un ordinateur",
        content: "Vous saurez identifier et mettre en œuvre les meilleures pratiques ergonomiques pour travailler devant un ordinateur, minimisant ainsi les tensions musculo-squelettiques et améliorant le confort et l'efficacité au travail.",
        color: "#fff4d9",
      },
      {
        title: "Gestion du port de charges",
        content: "Vous serez capable de reconnaître et d'appliquer les techniques correctes de manutention manuelle et le port de charges pour les différents métiers, réduisant le risque de blessures et améliorant la sécurité au travail.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Qualité-de-vie-au-travail',
    title: "Qualité de vie au travail",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Qualité de Vie au Travail (QVT) est une aventure immersive dans le monde du bien-être professionnel. Elle est destinée à équiper les participants avec des outils et des stratégies pour favoriser un environnement de travail sain et stimulant. En abordant des thèmes clés tels que la compréhension de la QVT, la mesure de la performance, la prévention des risques psychosociaux et la gestion du stress, cette formation vise à promouvoir un milieu de travail où le bien-être et l'efficacité vont de pair.",
    nbHeure: "21 Heures (3 jours)",
    src: "/images/Qualité de vie au travail.png",
    options: [
      {
        title: "21 Heures (3 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de la QVT",
        content: "Vous apprendrez à définir et comprendre les composantes de la qualité de vie au travail, en explorant comment elles influencent la satisfaction et la productivité. Vous serez en mesure de diagnostiquer les besoins spécifiques de votre organisation en matière de QVT.",
        color: "#E0EEF7",
      },
      {
        title: "Mesure de la performance",
        content: "Vous acquerrez les compétences pour évaluer et mesurer la performance liée à la QVT dans votre entreprise. Cela comprend l'apprentissage des indicateurs clés, la mise en œuvre de sondages et l'analyse des données pour une amélioration continue.",
        color: "#D3EEDC",
      },
      {
        title: "Implémenter la QVT",
        content: "Vous saurez mettre en place des initiatives et des politiques qui renforcent la qualité de vie au travail. Vous développerez des stratégies pour intégrer la QVT dans la culture d'entreprise et engager vos collaborateurs dans cette démarche.",
        color: "#fff4d9",
      },
      {
        title: "Prévention des RPS",
        content: "Vous serez capable d'identifier les facteurs de risques psychosociaux et d'élaborer des plans de prévention efficaces. En apprenant à reconnaître les signes d'alerte, vous contribuerez à créer un environnement de travail plus sécurisant et respectueux de la santé mentale.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Gestion-du-stress',
    title: "Gestion du stress",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Gestion du stress est un parcours formatif essentiel destiné aux professionnels souhaitant maîtriser les techniques de management du stress, un élément incontournable dans le monde du travail contemporain. Cette formation est structurée pour fournir une compréhension approfondie des mécanismes du stress, ainsi que pour enseigner des stratégies pratiques permettant de le gérer efficacement dans divers environnements professionnels. En alliant théorie et pratique, les participants apprendront à anticiper et à désamorcer les situations potentiellement stressantes, à utiliser des techniques de relaxation éprouvées, et à gérer les conflits de manière à réduire leur impact stressant.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Gestion du stress.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension du stress",
        content: "Vous apprendrez à identifier les causes et les mécanismes du stress, en comprenant comment il affecte tant la performance individuelle que le bien-être général. Cette compréhension vous permettra d'intervenir de manière proactive pour minimiser son apparition.",
        color: "#E0EEF7",
      },
      {
        title: "Gestion des situations de stress",
        content: "Vous acquerrez les compétences pour gérer les pics de stress aigus, en restant centré et efficace même dans les situations les plus pressantes. Vous développerez des réflexes solides pour maintenir l'équilibre sous pression.",
        color: "#D3EEDC",
      },
      {
        title: "Techniques de relaxation",
        content: "Vous saurez mettre en œuvre des techniques de relaxation variées, allant de la respiration profonde à la méditation guidée, pour réduire la tension corporelle et mentale et restaurer votre énergie.",
        color: "#fff4d9",
      },
      {
        title: "Prévention du stress",
        content: "Vous serez capable d'appliquer des stratégies préventives pour éviter l'accumulation de stress dans différents environnements, en créant une atmosphère de travail plus calme et en instaurant des pratiques qui favorisent la résilience face aux défis quotidiens.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Sauveteur-Secouriste-du-travail',
    title: "Sauveteur Secouriste du travail (SST)",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Sauveteur Secouriste du Travail (SST) est essentielle pour tout individu désireux de contribuer à la sécurité et au bien-être sur son lieu de travail. Elle est spécifiquement conçue pour fournir les compétences pratiques et la connaissance nécessaire pour intervenir efficacement en cas d'accident et pour participer activement à la prévention des risques professionnels. En complétant cette formation, les participants seront certifiés comme Sauveteurs Secouristes du Travail, un rôle crucial dans le maintien de la sécurité au quotidien dans l'entreprise.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Sauveteur Secouriste du travail ( SST ).png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Intervention d'urgence ",
        content: "Vous apprendrez à évaluer une scène d'accident et à exécuter les gestes de premiers secours nécessaires pour prendre en charge une victime en attendant l'arrivée des secours professionnels, réduisant ainsi les risques de complications ou de séquelles.",
        color: "#E0EEF7",
      },
      {
        title: "Prévention des risques",
        content: "Vous acquerrez les compétences pour identifier les dangers potentiels sur le lieu de travail et pour recommander ou mettre en œuvre des mesures préventives afin de minimiser les risques d'accidents ou de maladies professionnelles.",
        color: "#D3EEDC",
      },
      {
        title: "Application des compétences SST",
        content: " Vous saurez comment appliquer vos connaissances et compétences en matière de secourisme dans le cadre spécifique de votre environnement professionnel, en contribuant ainsi à la culture de la sécurité de votre entreprise.",
        color: "#fff4d9",
      },
      {
        title: "Certification SST",
        content: "Vous serez capable de démontrer vos compétences en matière de secourisme en milieu de travail pour obtenir votre certificat SST, valide pour une période de 24 mois, après quoi une formation de recyclage sera nécessaire pour maintenir la certification à jour.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Incendie-en-entreprise',
    title: "Incendie en entreprise",
    priceActual: "Devis",
    category: "",
    categoryText: "",
    description: "La formation Incendie en entreprise : extincteur & évacuation est essentielle pour toute personne en milieu professionnel, offrant les compétences vitales pour agir efficacement en cas d'urgence incendie. Elle prépare les employés à identifier rapidement les risques, à réagir promptement lors d'un début d'incendie et à mener une évacuation ordonnée et sécurisée. Les participants seront formés à la fois théoriquement et pratiquement, leur permettant de devenir des acteurs clés dans la prévention et la gestion des situations d'urgence.",
    nbHeure: "1⁄2 Jours (soit 3h30)",
    src: "/images/Incendie en entreprise.png",
    options: [
      {
        title: "1⁄2 JOUR soit 3h30",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Réaction et isolation des risques",
        content: "Vous apprendrez à identifier les premiers signes d'un incendie, à isoler les risques immédiatement et à alerter les secours avec précision et efficacité, en suivant des protocoles établis pour minimiser les dommages et protéger les vies.",
        color: "#E0EEF7",
      },
      {
        title: "Principes d'évacuation",
        content: "Vous acquerrez une compréhension claire des procédures d'évacuation, en connaissant les itinéraires d'évacuation et en apprenant à diriger calmement les individus vers la sécurité tout en maintenant l'ordre et en évitant la panique.",
        color: "#D3EEDC",
      },
      {
        title: "Rôles et responsabilités en évacuation",
        content: " Vous saurez identifier et comprendre les rôles spécifiques du personnel désigné pour l'évacuation, y compris les chargés d'évacuation, les guides et les serres-files, et maîtriser les techniques et consignes pour une évacuation efficace et sécurisée.",
        color: "#fff4d9",
      },
      {
        title: "Utilisation d'extincteurs",
        content: "Vous serez capable de mettre en œuvre les tentatives d'extinction des feux naissants à l'aide d'extincteurs, en respectant les consignes de sécurité et en utilisant la bonne technique pour maximiser les chances de réussite et minimiser les risques.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'HACCP-Hygiène-Alimentaire',
    title: "HACCP : Hygiène Alimentaire",
    priceActual: "320 €",
    category: "",
    categoryText: "",
    description: "La formation HACCP : Hygiène Alimentaire est spécifiquement structurée pour offrir une compréhension approfondie des principes de l'hygiène alimentaire et de l'analyse des risques et points critiques pour leur maîtrise (HACCP). Ce cours est essentiel pour toute personne ou entreprise impliquée dans la manipulation des denrées alimentaires, de la production à la distribution. Il vise à garantir que les participants soient capables d'identifier et de contrôler les risques sanitaires liés aux aliments, assurant ainsi la sécurité des consommateurs.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/HACCP Hygiène Alimentaire.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Fondamentaux de l'hygiène alimentaire",
        content: "Vous apprendrez à maîtriser les principes de base de l'hygiène alimentaire, essentiels à la préparation, au traitement et à la conservation des aliments, afin de prévenir la contamination et les maladies d'origine alimentaire.",
        color: "#E0EEF7",
      },
      {
        title: "Principes HACCP",
        content: "Vous acquerrez une compréhension solide des sept principes de l'HACCP et de la manière de les appliquer dans le cadre de votre activité. Cela inclut l'analyse des dangers, la détermination des points critiques de contrôle (CCP), et la mise en place de systèmes de surveillance efficaces.",
        color: "#D3EEDC",
      },
      {
        title: "Gestion des risques alimentaires",
        content: "Vous saurez identifier et évaluer les risques potentiels dans la chaîne alimentaire, en mettant en œuvre des stratégies de gestion pour les atténuer ou les éliminer, assurant ainsi la sécurité alimentaire à chaque étape de la production.",
        color: "#fff4d9",
      },
      {
        title: "Procédures de vérification et de documentation",
        content: "Vous serez capable de développer et de maintenir des procédures de vérification et de documentation conformes aux normes réglementaires, garantissant que les pratiques d'hygiène alimentaire sont continuellement surveillées, évaluées et améliorées.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Habilitation-électrique-Photovoltaïque',
    title: "Habilitation électrique Photovoltaïque",
    priceActual: "Devis",
    category: "",
    categoryText: "",
    description: "La formation Habilitation électrique Photovoltaïque est spécialement conçue pour les professionnels œuvrant dans le secteur de l'énergie solaire. Elle vise à actualiser et renforcer les connaissances en matière de sécurité électrique, en mettant l'accent sur les spécificités du photovoltaïque. Cette formation est cruciale pour garantir que les opérations et la maintenance des installations photovoltaïques sont effectuées dans le respect des normes de sécurité les plus strictes. Connaissance des dangers de l'électricité : Vous apprendrez",
    nbHeure: "1⁄2 JOUR soit 3h30",
    src: "/images/Habilitation électrique Photovoltaïque.png",
    options: [
      {
        title: "1⁄2 JOUR soit 3h30",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Connaissance des dangers de l'électricité",
        content: "Vous apprendrez à identifier et comprendre les différents dangers liés à l'électricité dans le contexte du photovoltaïque. Vous serez capable d'analyser le risque électrique et de prendre les mesures appropriées pour travailler en sécurité.",
        color: "#E0EEF7",
      },
      {
        title: "Prévention et organisation du travail",
        content: "Vous acquerrez les compétences pour intégrer efficacement la prévention des risques électriques dans l'organisation quotidienne du travail. Vous apprendrez à élaborer des procédures qui réduisent le risque d'accidents et améliorent la sécurité globale de vos opérations.",
        color: "#D3EEDC",
      },
      {
        title: "Gestion des accidents électriques",
        content: "Vous saurez la conduite à tenir en cas d'accident d'origine électrique. De la réaction immédiate à l'administration des premiers secours, cette compétence est vitale pour la sécurité de l'individu affecté et celle de l'équipe environnante.",
        color: "#fff4d9",
      },
      {
        title: "Intervention en cas d'incendie électrique",
        content: "Vous serez capable de reconnaître un incendie d'origine électrique et de connaître les étapes à suivre pour intervenir de manière sûre et efficace, en minimisant les dommages et en protégeant la vie humaine.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Habilitation-électrique-Photovoltaïque-BP-BR',
    title: "Habilitation électrique : Photovoltaïque BP / BR",
    priceActual: "650 €",
    category: "",
    categoryText: "",
    description: "La formation Habilitation électrique : Photovoltaïque BP / BR est une qualification essentielle pour les professionnels travaillant dans le domaine de l'énergie solaire. Elle vise à doter les participants des connaissances et des compétences nécessaires pour intervenir en toute sécurité sur des installations photovoltaïques, en respectant les normes en vigueur. Ce programme combine la théorie réglementaire et les pratiques de terrain pour assurer une compréhension et une application rigoureuses des consignes de sécurité.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/Habilitation électrique Photovoltaïque BP BR.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Normes de sécurité NFC 18510 ",
        content: "Vous apprendrez à maîtriser les spécificités de la norme NFC 18510, garantissant que toutes les interventions sur les systèmes photovoltaïques sont effectuées conformément aux réglementations actuelles et aux standards de sécurité.",
        color: "#E0EEF7",
      },
      {
        title: "Intervention sécurisée sur installations PV",
        content: "Vous acquerrez les compétences pour réaliser des opérations de pose, de raccordement et de maintenance sur des panneaux photovoltaïques, en mettant en œuvre des procédures qui assurent votre sécurité et celle de l'installation.",
        color: "#D3EEDC",
      },
      {
        title: "Prévention des risques électriques",
        content: "Vous saurez identifier et prévenir les risques électriques spécifiques aux systèmes photovoltaïques, y compris la gestion des situations d'urgence et l'application des mesures de premiers secours adaptées aux accidents électriques.",
        color: "#fff4d9",
      },
      {
        title: "Maintenance sécuritaire et efficace",
        content: "Vous serez capable de mener des opérations de maintenance préventive et corrective en respectant les protocoles de sécurité, pour assurer la longévité et la performance optimale des installations photovoltaïques.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Habilitation-électrique-BE-manœuvre',
    title: "Habilitation électrique : BE manœuvre",
    priceActual: "650 €",
    category: "",
    categoryText: "",
    description: "La formation Habilitation électrique: BE manœuvre est un programme intensif qui vous prépare à opérer en toute sécurité dans les environnements électriques et à actualiser vos compétences conformément aux réglementations en vigueur. Que vous soyez un professionnel expérimenté cherchant à revalider votre habilitation ou un nouveau venu dans le domaine, cette formation vous offrira les compétences et les connaissances nécessaires pour travailler avec assurance et compétence.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Habilitation-électrique-BE-manœuvre.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Sécurité opérationnelle",
        content: "Vous apprendrez à appliquer les normes de sécurité les plus récentes dans vos opérations quotidiennes, en vous assurant que toutes les manœuvres électriques sont réalisées selon les protocoles stricts pour prévenir les accidents et garantir votre sécurité et celle de vos collègues.",
        color: "#E0EEF7",
      },
      {
        title: "Mise à jour des compétences",
        content: "Vous acquerrez une compréhension approfondie des évolutions récentes dans le domaine de l'électricité, y compris les nouvelles méthodes, outils et réglementations, pour rester à la pointe de votre profession.",
        color: "#D3EEDC",
      },
      {
        title: "Revalidation de l'habilitation",
        content: "Vous saurez naviguer dans le processus de revalidation de votre titre d'habilitation électrique BE manœuvre, en vous assurant que votre certification est reconnue et à jour pour une durée supplémentaire de trois ans.",
        color: "#fff4d9",
      },
      {
        title: "Pratiques de travail sûres",
        content: "Vous serez capable d'identifier et de gérer les risques potentiels dans les opérations d'ordre électrique, en renforçant les bonnes pratiques de travail qui soutiennent la sécurité à long terme et le bien-être dans l'environnement de travail.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Préparation-CACES',
    title: "Préparation CACES : Gerbeur R 485",
    priceActual: "710 €",
    category: "",
    categoryText: "",
    description: "La formation Préparation CACES : Gerbeur R 485 est un programme essentiel pour les professionnels de la logistique et de la manutention qui souhaitent maîtriser la conduite sécuritaire des gerbeurs. Ce cours intensif vise à fournir une compréhension approfondie de la réglementation en vigueur, des techniques de manœuvre et de l'entretien de ces engins spécifiques. En mettant l'accent sur la sécurité et la compétence opérationnelle, cette formation est idéale pour ceux qui cherchent à obtenir leur CACES R485 et à accroître leur employabilité dans le secteur.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Préparation CACES Gerbeur R 485.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise des catégories de gerbeurs",
        content: "Vous apprendrez à conduire les deux catégories de gerbeurs à conducteur accompagnant, en vous familiarisant avec les spécificités techniques de chaque catégorie, pour une utilisation optimale et adaptée aux différents contextes de travail.",
        color: "#E0EEF7",
      },
      {
        title: "Sécurité opérationnelle",
        content: "Vous acquerrez les compétences nécessaires pour utiliser les gerbeurs en toute sécurité, en intégrant les meilleures pratiques et les mesures de prévention des accidents, garantissant ainsi la protection de l'utilisateur et la préservation de l'environnement de travail.",
        color: "#D3EEDC",
      },
      {
        title: "Réglementation et entretien",
        content: "Vous saurez intégrer la réglementation relative à l'utilisation des gerbeurs et vous serez formé à l'entretien courant de l'engin, ce qui est crucial pour assurer la longévité et la fiabilité de l'équipement.",
        color: "#fff4d9",
      },
      {
        title: "Certification CACES R485",
        content: "Vous serez capable de réussir les évaluations théoriques et pratiques requises pour l'obtention du CACES R485, attestant de votre capacité à opérer ces machines conformément aux normes de sécurité actuelles.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Créer-son-entreprise-de-A-à-Z',
    title: "Créer son entreprise de A à Z",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Créer son entreprise de A à Z offre une immersion complète dans le processus entrepreneurial, depuis la genèse de l'idée jusqu'à la gestion quotidienne de la nouvelle entreprise. Elle est spécialement conçue pour ceux qui aspirent à devenir entrepreneurs et souhaitent acquérir une compréhension solide des fondations d'une entreprise prospère. À travers des modules interactifs et des études de cas pratiques, les participants seront guidés à travers les différentes étapes de la création d'entreprise, en mettant l'accent sur la stratégie, la gestion, et l'innovation.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/Créer son entreprise de A à Z.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Méthodologie de création",
        content: "Vous comprendrez les étapes cruciales pour démarrer une entreprise, en apprenant à élaborer un plan d'affaires solide, à analyser le marché, et à structurer votre entreprise pour le succès à long terme.",
        color: "#E0EEF7",
      },
      {
        title: "Identification du projet",
        content: "Vous identifierez les éléments clés qui constituent l'âme de votre projet entrepreneurial, en évaluant la proposition de valeur unique de votre idée et en définissant clairement votre vision et vos objectifs.",
        color: "#D3EEDC",
      },
      {
        title: "Validation de l'idée",
        content: "Vous validerez la faisabilité de votre concept d'entreprise grâce à une analyse approfondie du marché, des clients potentiels et de la concurrence, en vous assurant que votre idée peut être transformée en une entreprise viable.",
        color: "#fff4d9",
      },
      {
        title: "Gestion d'entreprise",
        content: "Vous maîtriserez les compétences essentielles en gestion, en comprenant les finances, les opérations, et les ressources humaines, pour que votre entreprise fonctionne de manière efficace et conforme aux réglementations en vigueur.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Document-unique-obligatoire',
    title: "Document unique obligatoire",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation Document unique obligatoire est une formation essentielle pour tout responsable d’entreprise, manager de sécurité ou collaborateur impliqué dans la prévention des risques professionnels. Ce programme vous fournira une compréhension approfondie de la réglementation actuelle relative à la sécurité au travail et aux risques encourus en cas de non-conformité. Avec un accent mis sur l'application pratique, les participants apprendront à élaborer et à maintenir les documents uniques nécessaires pour leur entreprise, garantissant ainsi une gestion rigoureuse des risques professionnels.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Document unique obligatoire.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de la réglementation",
        content: "Vous apprendrez à naviguer dans le cadre légal complexe des risques professionnels, à comprendre les obligations de l'employeur et les droits des employés, ainsi que les conséquences d'une non-conformité, pour assurer une protection optimale de tous les acteurs de l'entreprise.",
        color: "#E0EEF7",
      },
      {
        title: "Conception de documents uniques",
        content: "Vous serez capable de créer les documents uniques qui sont essentiels à la sécurité de votre entreprise, en assimilant les méthodes et outils nécessaires pour rédiger des rapports détaillés et des évaluations des risques adaptées à votre environnement de travail spécifique.",
        color: "#D3EEDC",
      },
      {
        title: "Prévention des risques",
        content: "Vous acquerrez des compétences pour identifier, évaluer et contrôler les risques professionnels, en mettant en œuvre des stratégies de prévention efficaces qui contribueront à la sécurité et au bien-être des employés sur le lieu de travail.",
        color: "#fff4d9",
      },
      {
        title: "Maintenance et mise à jour des documents",
        content: "Vous saurez assurer la pertinence et l'actualité de votre documentation en intégrant les dernières évolutions législatives et en adaptant les documents aux changements au sein de l'entreprise, pour une conformité continue et une gestion proactive des risques.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Formation-CSE',
    title: "Formation cse",
    priceActual: "1940 €",
    category: "",
    categoryText: "",
    description: "La formation CSE - Membre des entreprises de plus de 50 salariés est spécialement conçue pour fournir aux membres du Comité Social et Économique (CSE) les compétences essentielles pour une représentation efficace du personnel. En mettant l'accent sur les aspects légaux et pratiques de leurs responsabilités, cette formation offre un éclairage complet sur les rôles, missions et moyens d'action du CSE, tout en soulignant l'importance de leur rôle dans la prévention des risques professionnels et la promotion de la santé et de la sécurité au travail.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/Formation cse.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise des responsabilités du CSE",
        content: "Vous apprendrez à comprendre en profondeur les rôles et les missions attribués au CSE, en explorant les aspects juridiques et pratiques pour exercer efficacement vos responsabilités et droits en tant que membre du comité.",
        color: "#E0EEF7",
      },
      {
        title: "Prévention des risques",
        content: "Vous acquerrez les compétences pour identifier et évaluer les risques dans l'environnement de travail, en vous appropriant des méthodes et outils éprouvés pour agir en tant que préventeur et garantir le bien-être des salariés.",
        color: "#D3EEDC",
      },
      {
        title: "Santé et sécurité au travail",
        content: "Vous saurez être un acteur proactif dans la mise en œuvre et l'amélioration continue de la politique de santé et de sécurité au sein de votre entreprise, en contribuant à la création d'un lieu de travail sécurisé et en promouvant une culture de prévention.",
        color: "#fff4d9",
      },
      {
        title: "Contribution à la politique de prévention",
        content: "Vous serez capable d'apporter votre pierre à l'édifice en matière de politique de prévention des risques professionnels, en participant activement à l'élaboration et à la mise en place de stratégies efficaces pour prévenir les accidents et les maladies liés au travail.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Devenir-Formateur',
    title: "Devenir formateur",
    priceActual: "5200 €",
    category: "",
    categoryText: "",
    description: "La formation Devenir Formateur est spécialement conçue pour celles et ceux qui aspirent à transmettre leur savoir et à se perfectionner dans l'art de la pédagogie. Elle couvre un spectre étendu de compétences nécessaires pour concevoir, animer et évaluer des programmes de formation efficaces. Les participants seront guidés à travers les fondements de la formation adulte, en adoptant des méthodes innovantes et en intégrant les dernières avancées en neuro-pédagogie et en technologie éducative.",
    nbHeure: "48 Heures (8 Jours)",
    src: "/images/Devenir formateur.png",
    options: [
      {
        title: "48 Heures (8 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Qualification des besoins de formation ",
        content: "Vous apprendrez à analyser avec précision les besoins de formation au sein d'une organisation, afin de déterminer les objectifs pédagogiques alignés sur les besoins réels des apprenants et de l'entreprise.",
        color: "#E0EEF7",
      },
      {
        title: "Conception d'une formation pertinente",
        content: "Vous acquerrez des techniques pour structurer une action de formation en sélectionnant et en organisant les contenus de manière logique, en veillant à la pertinence et à l'impact des modules de formation proposés.",
        color: "#D3EEDC",
      },
      {
        title: "Posture de formateur",
        content: "Vous saurez adopter une posture de formateur professionnelle, en développant une présence et une autorité naturelles qui facilitent l'engagement des apprenants et encouragent un environnement d'apprentissage positif.",
        color: "#fff4d9",
      },
      {
        title: "Facilitation de l'apprentissage",
        content: "Vous serez capable d'appliquer des stratégies pédagogiques pour faciliter l'apprentissage et le transfert des compétences, en veillant à ce que les apprenants puissent non seulement assimiler l'information mais aussi l'appliquer concrètement.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Conseiller-de-vente-en-magasin-et-à-distance',
    title: "Conseiller de vente en magasin et à distance",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Conseiller de vente en magasin et à distance est conçue pour équiper les professionnels du commerce avec les compétences nécessaires pour exceller dans un environnement de vente moderne, qui exige une maîtrise du numérique et une approche client-centrique tant en magasin qu'en ligne. Cette formation vous permettra de naviguer avec aisance entre les outils digitaux et les techniques de vente traditionnelles pour maximiser vos résultats et renforcer la satisfaction client.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Conseiller de vente en magasin et à distance.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise des outils numériques ",
        content: "Vous apprendrez à intégrer les dernières technologies numériques dans votre stratégie de vente, en utilisant des plateformes en ligne et des solutions mobiles pour atteindre et engager les clients en magasin et à distance, augmentant ainsi la portée et l'efficacité de vos efforts commerciaux.",
        color: "#E0EEF7",
      },
      {
        title: "Analyse de marché connectée ",
        content: "Vous acquerrez les compétences pour exploiter les outils d'analyse de données pour comprendre votre marché cible, en identifiant les tendances et les comportements des consommateurs, ce qui vous permettra d'adapter votre approche de vente et de stock pour répondre précisément aux besoins de vos clients.",
        color: "#D3EEDC",
      },
      {
        title: "Stratégies de vente participative",
        content: "Vous saurez impliquer le client dans le processus de vente et de promotion, en créant une expérience d'achat interactive et personnalisée qui encourage non seulement l'achat mais aussi la fidélisation et la promotion de votre marque par le client lui-même.",
        color: "#fff4d9",
      },
      {
        title: "Promotion multicanale",
        content: "Vous serez capable d'élaborer et de mettre en œuvre des stratégies de promotion efficaces qui transcendent les canaux de vente, en assurant une présence cohérente et convaincante de vos produits et services, que ce soit en face à face, sur les réseaux sociaux, ou via des plateformes de vente en ligne.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Technique-de-ventes-omnicanal',
    title: "Technique de ventes omnicanal",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Technique de ventes omnicanal est spécialement élaborée pour répondre aux exigences du marché actuel, où la digitalisation des ventes est devenue une nécessité. Elle vise à doter les professionnels de la vente des compétences les plus actuelles pour exceller à la fois en magasin et en ligne, en utilisant les outils numériques à leur plein potentiel. En mettant l'accent sur l'analyse de données, l'engagement client et une approche de vente personnalisée, cette formation est idéale pour ceux qui cherchent à affiner leur processus de vente et à maximiser leur performance commerciale.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Technique de ventes omnicanal.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Outils numériques et augmentation des ventes",
        content: "Vous apprendrez comment utiliser des outils numériques innovants pour non seulement augmenter vos ventes en magasin, mais aussi à distance, en tirant parti des technologies pour atteindre et dépasser vos objectifs de vente.",
        color: "#E0EEF7",
      },
      {
        title: "Analyse de marché et ciblage client",
        content: "Vous acquerrez des compétences en matière d'utilisation d'outils d'analyse de données connectés, vous permettant d'identifier précisément votre cible de marché et d'analyser les tendances pour adapter vos stratégies de vente de manière proactive.",
        color: "#D3EEDC",
      },
      {
        title: "Engagement client et promotion",
        content: "Vous saurez rendre le client un acteur clé de la vente et de la promotion de vos produits et services, en créant une expérience d'achat interactive et en encourageant l'engagement à travers des stratégies de vente centrées sur le client.",
        color: "#fff4d9",
      },
      {
        title: "Méthode de vente axée sur le client ",
        content: "Vous serez capable d'augmenter votre efficacité en situation de vente, que ce soit face à face ou à distance, en adoptant une méthode de vente qui répond directement aux attentes et besoins du client, tout en construisant une relation de confiance durable.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Développer-l-approche-commercial',
    title: "Développer l’approche commercial",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Développer l’approche commerciale est spécialement conçue pour les professionnels souhaitant exceller dans l'art de la vente et du service client dans le secteur de l'hôtellerie et de la restauration. Elle offre des insights précieux et des compétences pratiques pour transformer chaque interaction avec le client en une opportunité de vente et de fidélisation. En mettant l'accent sur une approche commerciale stratégique, cette formation vous guide à travers les nuances de la relation client et les spécificités de la vente dans cet environnement unique.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Développer l’approche commercial.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension des enjeux relationnels",
        content: "Vous apprendrez à saisir l'importance vitale de la relation client dans l'industrie de l'hôtellerie et de la restauration, en identifiant les opportunités de vente à chaque point de contact et en améliorant l'expérience client pour stimuler la croissance des revenus.",
        color: "#E0EEF7",
      },
      {
        title: "Techniques de vente spécialisées",
        content: "Vous acquerrez des méthodes de vente personnalisées pour l'hôtellerie et la restauration, en apprenant à adapter votre approche en fonction des divers besoins et attentes des clients, afin de maximiser les opportunités de vente tout en maintenant un service haut de gamme.",
        color: "#D3EEDC",
      },
      {
        title: "Montée en gamme et proposition proactive",
        content: "Vous saurez devenir force de proposition en adoptant des techniques de montée en gamme qui encouragent les clients à opter pour des services de valeur ajoutée, augmentant ainsi les revenus moyens par client tout en enrichissant leur expérience.",
        color: "#fff4d9",
      },
      {
        title: "Service d'excellence pour tous les clients",
        content: "Vous serez capable d'élever la qualité de service offerte, en gérant efficacement les clients les plus exigeants et en transformant les situations difficiles en expériences positives, contribuant à une fidélisation durable et profitable pour l'établissement.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Formation-négociation-commerciale-Grands-Comptes',
    title: "Formation négociation commerciale Grands Comptes",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Négociation Commerciale Grands Comptes est spécialement conçue pour les professionnels désireux de maîtriser les subtilités de la négociation et du développement des grands comptes. Cette formation propose une immersion approfondie dans les techniques avancées de négociation et de stratégie commerciale, adaptées au contexte exigeant des grands comptes. Les participants seront guidés pour élaborer des stratégies sur mesure, mobiliser des équipes internes et négocier efficacement dans un environnement commercial complexe et compétitif.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Formation négociation commerciale Grands Comptes.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Stratégie de développement grand compte",
        content: "Vous apprendrez à construire des stratégies de développement spécifiques pour les grands comptes, en identifiant les opportunités clés et en élaborant un plan d'action ciblé pour maximiser le potentiel de croissance et renforcer les relations avec les clients majeurs.",
        color: "#E0EEF7",
      },
      {
        title: "Plan d'actions commerciales",
        content: "Vous acquerrez les compétences pour décliner efficacement la stratégie globale en un plan d'actions commerciales concret, en alignant les objectifs à long terme avec les actions quotidiennes, pour une mise en œuvre fluide et efficace.",
        color: "#D3EEDC",
      },
      {
        title: "Influence sur le groupe de décision client",
        content: "Vous saurez identifier et interagir stratégiquement avec les différents membres du groupe de décision chez le client. Vous apprendrez à comprendre leurs motivations et à adapter votre approche pour influencer efficacement le processus de décision.",
        color: "#fff4d9",
      },
      {
        title: "Mobilisation des équipes internes",
        content: " Vous serez capable de mobiliser et coordonner les équipes internes de votre entreprise, en assurant une collaboration efficace et un engagement envers les objectifs communs pour le développement des grands comptes.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Formation-Recouvrement-de-créances',
    title: "Formation Recouvrement de créances",
    priceActual: "2150 €",
    category: "",
    categoryText: "",
    description: "La formation Recouvrement de créances est spécialement élaborée pour fournir aux professionnels les compétences et techniques nécessaires pour optimiser le processus de recouvrement des paiements en souffrance. Cette formation pratique et concrète aborde des méthodes efficaces pour gérer et réduire les retards de paiement, tout en préservant les relations commerciales. Les participants seront armés pour prendre des décisions éclairées concernant les actions amiables et judiciaires, assurant la santé financière de leur entreprise.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/Formation Recouvrement de créances.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Organisation de la relance",
        content: "Vous apprendrez à structurer le processus de relance de manière stratégique, en identifiant les paiements en retard et en défaut, et en mettant en place un système de suivi rigoureux pour optimiser les chances de recouvrement rapide et efficace.",
        color: "#E0EEF7",
      },
      {
        title: "Procédures de recouvrement",
        content: "Vous acquerrez la maîtrise des différentes procédures amiables et judiciaires, comprenant les aspects légaux et éthiques du recouvrement de créances. Vous serez capable de choisir et d'implémenter la procédure la plus adaptée en fonction de la situation spécifique de chaque créance.",
        color: "#D3EEDC",
      },
      {
        title: "Techniques de recouvrement efficaces",
        content: "Vous saurez évaluer et sélectionner la technique de recouvrement la plus efficiente en termes de coût et de délai. Vous développerez des compétences pour négocier et appliquer des solutions qui maximisent le recouvrement tout en minimisant les dépenses.",
        color: "#fff4d9",
      },
      {
        title: "Analyse coût-délai",
        content: "Vous serez capable d'analyser et de comparer les coûts et les délais associés à chaque technique de recouvrement pour prendre des décisions informées qui amélioreront les marges bénéficiaires et le flux de trésorerie de votre entreprise.",
        color: "#D3EEDC",
      },
    ],
  },
  {
    id: 'Recouvrement-de-créances-amiable-&-contentieux',
    title: "Recouvrement de créances amiable & contentieux",
    priceActual: "1688 €",
    category: "",
    categoryText: "",
    description: "La formation Recouvrement de créances amiable & contentieux est conçue pour les professionnels souhaitant maîtriser les aspects financiers et commerciaux du recouvrement de créances. Ce programme complet vous permettra de comprendre les enjeux liés à la gestion du crédit clients et de développer des compétences spécifiques dans la mise en œuvre efficace des processus de recouvrement. À travers des scénarios pratiques et des études de cas, vous apprendrez à naviguer avec assurance dans le paysage complexe du recouvrement amiable et contentieux.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Recouvrement de créances amiable & contentieux.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Enjeux financiers et commerciaux",
        content: "Vous comprendrez les implications financières et commerciales de la gestion du crédit clients, en analysant comment un recouvrement efficace contribue à la santé financière de l'entreprise tout en préservant les relations avec les clients.",
        color: "#E0EEF7",
      },
      {
        title: "Recouvrement amiable",
        content: "Vous maîtriserez les techniques de négociation et de communication nécessaires pour mettre en œuvre un processus de recouvrement amiable, en respectant les droits des clients tout en assurant la récupération des créances dues.",
        color: "#D3EEDC",
      },
      {
        title: "Méthodologie de relance téléphonique",
        content: "Vous développerez une méthodologie éprouvée pour la relance téléphonique, en apprenant à créer des scripts de communication efficaces, à gérer les objections et à instaurer un dialogue constructif qui incite au règlement des impayés.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Conception-des-vidéos-Motion-Design',
    title: "Conception des vidéos Motion Design",
    priceActual: "745 €",
    category: "",
    categoryText: "",
    description: "La formation Conception des vidéos Motion Design est spécialement élaborée pour les créatifs souhaitant maîtriser l'art du motion design et de l'animation graphique. Englobant les principes de conception graphique et les compétences techniques, ce programme transforme les étudiants en concepteurs visuels compétents, capables de produire des contenus animés captivants pour diverses plateformes. En plongeant dans les fonctionnalités avancées des logiciels de pointe, les participants gagneront en confiance et en autonomie pour donner vie à leurs visions créatives.",
    nbHeure: "7 Heures (1 Jours)",
    src: "/images/Conception des vidéos Motion Design.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Conception graphique",
        content: "Vous apprendrez à concevoir des éléments graphiques attrayants et fonctionnels pour les interfaces et supports de communication, en assimilant les principes fondamentaux du design et en appliquant les tendances actuelles pour un impact visuel maximal.",
        color: "#E0EEF7",
      },
      {
        title: "Fondements du Motion Design",
        content: "Vous acquerrez des bases solides en motion design pour créer des supports professionnels et pertinents. Cette compétence essentielle vous permettra de comprendre la narration visuelle et de l'appliquer efficacement à vos projets animés.",
        color: "#D3EEDC",
      },
      {
        title: "Maîtrise du logiciel",
        content: "Vous saurez naviguer avec aisance dans l'interface et utiliser les fonctionnalités principales des logiciels de motion design. Cette connaissance approfondie vous donnera la capacité de réaliser des animations complexes et de haute qualité.",
        color: "#fff4d9",
      },
      {
        title: "Autonomie créative",
        content: "Vous serez capable d'utiliser le logiciel de manière autonome, vous permettant d'expérimenter et de concrétiser vos idées sans dépendre constamment d'une assistance technique. Cette indépendance est cruciale pour l'innovation et l'efficacité dans le processus créatif.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Edition-de-site-web',
    title: "Edition de site web",
    priceActual: "745 €",
    category: "",
    categoryText: "",
    description: "La formation Édition de site web est spécialement conçue pour les professionnels souhaitant maîtriser les outils et stratégies nécessaires à la création et à la gestion d'un site web efficace. Ce cursus complet vous munira des compétences pour améliorer la notoriété et la visibilité de votre entreprise sur Internet, un enjeu crucial dans l'économie numérique actuelle. En mettant l'accent sur les techniques de vente en ligne et l'acquisition client, cette formation est un incontournable pour tout entrepreneur, marketeur ou responsable commercial désireux de propulser son activité dans l'ère digitale.",
    nbHeure: "7 Heures (1 Jours)",
    src: "/images/Edition de site web.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Optimisation de la visibilité",
        content: "Vous apprendrez à utiliser les dernières techniques de référencement (SEO) et de marketing de contenu pour augmenter la présence en ligne de votre entreprise, en assurant que votre site web atteigne et maintienne une position dominante dans les résultats de recherche.",
        color: "#E0EEF7",
      },
      {
        title: "Stratégies de vente en ligne",
        content: "Vous acquerrez des compétences clés pour transformer votre site en une puissante plateforme de vente, en intégrant des outils d'e-commerce efficaces et en élaborant des parcours utilisateurs optimisés pour maximiser les conversions de prospects en clients payants.",
        color: "#D3EEDC",
      },
      {
        title: "Acquisition de clients",
        content: "Vous saurez déployer des campagnes marketing numériques ciblées et utiliser des analyses web pour attirer de nouveaux clients. Vous comprendrez comment exploiter les réseaux sociaux, le marketing par courriel et la publicité en ligne pour étendre votre portée et capturer l'intérêt des prospects.",
        color: "#fff4d9",
      },
      {
        title: "Fidélisation de la clientèle",
        content: "Vous serez capable de mettre en œuvre des stratégies pour construire une relation durable avec votre clientèle et d'utiliser des techniques de fidélisation pour encourager les achats répétés, augmentant ainsi le chiffre d'affaires de manière significative.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Pack-Adobe-tous-niveaux',
    title: "Pack Adobe tous niveaux",
    priceActual: "1750 €",
    category: "",
    categoryText: "",
    description: "Le Pack Adobe tous niveaux est une formation exhaustive qui vous emmène dans un voyage à travers les outils les plus puissants du design et de la publication assistée par ordinateur. Que vous soyez débutant cherchant à maîtriser les fondamentaux ou un professionnel désireux d'affiner vos compétences, cette formation s'adapte à votre niveau pour transformer votre manière de créer des documents visuellement captivants. Vous découvrirez les richesses des logiciels Adobe et apprendrez à les utiliser pour concrétiser vos idées créatives.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/Pack Adobe tous niveaux.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Mise en page de documents simples",
        content: "Vous apprendrez à naviguer dans l'interface d'Adobe InDesign et à utiliser les outils de base pour structurer des documents simples. De la sélection de modèles à l'ajustement des marges et à la disposition du texte, vous serez en mesure de composer des mises en page claires et attractives pour tout type de documents.",
        color: "#E0EEF7",
      },
      {
        title: "Création graphique avec Adobe Photoshop",
        content: "Vous acquerrez des compétences fondamentales en retouche et composition d'images avec Adobe Photoshop. Vous apprendrez à manipuler des images, à créer des graphismes et à préparer des visuels pour l'intégration dans divers documents, assurant ainsi que chaque page attire l'œil.",
        color: "#D3EEDC",
      },
      {
        title: "Élaboration de documents complexes",
        content: "Vous saurez aller au-delà des bases pour construire des documents complexes avec Adobe InDesign. Ce module avancé vous enseignera à gérer des projets de grande envergure, en intégrant des éléments graphiques et typographiques avancés et en utilisant des fonctionnalités comme les feuilles de style et les maquettes pour des résultats professionnels.",
        color: "#fff4d9",
      },
      {
        title: "Intégration multimédia avec Adobe Acrobat",
        content: "Vous serez capable de finaliser vos documents en y intégrant des éléments multimédias à l'aide d'Adobe Acrobat. Que ce soit pour ajouter des hyperliens, des vidéos, ou des animations, cette compétence est cruciale pour donner vie à vos documents et les rendre interactifs.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Rédacteur-WEB-et-IA',
    title: "Rédacteur WEB et IA",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Rédacteur web et IA est conçue pour initier les participants aux subtilités de la rédaction en ligne, tout en intégrant les innovations technologiques comme l'intelligence artificielle dans le processus créatif. Les stagiaires découvriront comment les principes de la rédaction classique s'entrelacent avec les exigences numériques actuelles pour produire un contenu engageant, pertinent et optimisé pour les moteurs de recherche.",
    nbHeure: "14 Heures (2 Jours)",
    src: "/images/Rédacteur WEB et IA.png",
    options: [
      {
        title: "14 Heures (2 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Techniques rédactionnelles fondamentales",
        content: "Vous maîtriserez les fondamentaux de la rédaction, en apprenant à structurer vos idées, à peaufiner votre style et à adapter votre ton selon les différents formats et publics, pour une communication écrite claire et impactante.",
        color: "#E0EEF7",
      },
      {
        title: "Écriture journalistique",
        content: "Vous connaîtrez les techniques spécifiques à l'écriture journalistique, y compris la recherche, la vérification des faits, et le storytelling, pour produire des contenus informatifs et captivants qui répondent aux standards du journalisme en ligne.",
        color: "#D3EEDC",
      },
      {
        title: "Spécificités de la rédaction web",
        content: "Vous identifierez les particularités de la rédaction pour le web, telles que la brièveté, l'interactivité, et l'accessibilité, pour créer des textes qui captent l'attention dans un environnement numérique encombré.",
        color: "#fff4d9",
      },
      {
        title: "Scénarisation pour le web",
        content: "Vous saurez élaborer des contenus scénarisés et structurés spécifiquement pour le web, en utilisant des techniques narratives pour engager les utilisateurs et les inciter à l'action.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'SKETCHUP-PRO',
    title: "SKETCHUP PRO",
    priceActual: "1570 €",
    category: "",
    categoryText: "",
    description: "La formation SketchUp Pro offre une immersion complète dans le monde de la modélisation 3D, permettant aux participants de maîtriser ce puissant outil de conception. Que vous soyez un architecte, un designer d'intérieur, ou un passionné de modélisation, cette formation est taillée sur mesure pour développer votre compétence dans la création d'espaces et d'objets en trois dimensions. Avec une combinaison de théorie et de pratique, les apprenants seront guidés pas à pas à travers les fonctionnalités de SketchUp, depuis la navigation dans l'espace 3D jusqu'à la présentation raffinée de leurs projets.",
    nbHeure: "21 Heures (3 Jours)",
    src: "/images/SKETCHUP PRO.png",
    options: [
      {
        title: "21 Heures (3 Jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Présentiel, Distanciel, Hybride",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Navigation 3D",
        content: "Vous apprendrez à vous déplacer aisément dans l'espace tridimensionnel de SketchUp, une compétence fondamentale pour visualiser et examiner vos modèles sous tous les angles, et poser ainsi les bases solides nécessaires à la création 3D précise et efficace.",
        color: "#E0EEF7",
      },
      {
        title: "Création et modification 3D",
        content: "Vous acquerrez des techniques avancées pour créer et modifier des géométries en 3D. Vous serez capable de construire des structures complexes, d'ajuster les dimensions, et de manipuler les formes pour concrétiser vos idées en modèles virtuels.",
        color: "#D3EEDC",
      },
      {
        title: "Textures personnalisées",
        content: "Vous saurez comment créer et appliquer vos propres textures dans SketchUp, ce qui vous donnera le pouvoir de personnaliser les surfaces et d'ajouter une touche de réalisme à vos modèles, en reflétant avec précision les matériaux du monde réel.",
        color: "#fff4d9",
      },
      {
        title: "Présentation et exportation",
        content: "Vous serez capable de mettre en scène les vues de votre modèle et de les exporter, en maîtrisant les outils de présentation pour communiquer efficacement vos idées et partager vos projets avec les clients ou les membres de l'équipe sous divers formats numériques.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Pack-application-bureautique-Essentiel',
    title: "Pack application bureautique Essentiel",
    priceActual: "1710 €",
    category: "",
    categoryText: "",
    description: "Le Pack application bureautique Essentiel est une formation complète destinée à vous outiller avec les compétences fondamentales en informatique nécessaires pour exceller dans le milieu professionnel contemporain. Elle est spécialement conçue pour les professionnels souhaitant maîtriser les outils bureautiques courants, améliorant ainsi leur efficacité au travail et leur capacité à collaborer efficacement en milieu numérique. En associant théorie et pratique, cette formation vous permettra de gérer les tâches quotidiennes avec aisance et professionnalisme.",
    nbHeure: "44 Heures",
    src: "/images/Pack application bureautique Essentiel.png",
    options: [
      {
        title: "44 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise du traitement de texte",
        content: "Vous apprendrez à créer et présenter des documents professionnels soignés, en maîtrisant les fonctions avancées de mise en forme et de mise en page d'un traitement de texte, ce qui est crucial pour la production de documents écrits de haute qualité.",
        color: "#E0EEF7",
      },
      {
        title: "Compétences avancées en tableur",
        content: "Vous acquerrez la capacité de saisir et de manipuler des données dans un tableur, en utilisant des formules et des outils d'analyse pour mettre à jour les informations et générer des rapports pertinents, contribuant à une meilleure prise de décision basée sur des données.",
        color: "#D3EEDC",
      },
      {
        title: "Utilisation d'outils collaboratifs",
        content: "Vous saurez exploiter les fonctionnalités des plateformes collaboratives pour communiquer efficacement avec votre équipe, partager des informations et co-travailler sur des projets en temps réel, renforçant ainsi la synergie et la productivité de l'équipe.",
        color: "#fff4d9",
      },
      {
        title: "Gestion efficace des communications",
        content: "Vous serez capable de trier, de traiter et de répondre de manière organisée aux courriels et au courrier, en utilisant des techniques de gestion du temps et de priorisation pour assurer une communication fluide et professionnelle.",
        color: "#fff4d9",
      },
      {
        title: "Gestion efficace des communications",
        content: "Vous serez capable de trier, de traiter et de répondre de manière organisée aux courriels et au courrier, en utilisant des techniques de gestion du temps et de priorisation pour assurer une communication fluide et professionnelle.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Plateforme-collaborative-Microsoft-Office-365',
    title: "Plateforme collaborative Microsoft Office 365",
    priceActual: "850 €",
    category: "",
    categoryText: "",
    description: "La formation Plateforme collaborative Microsoft Office 365 est spécialement conçue pour les professionnels qui souhaitent maîtriser l'écosystème de travail collaboratif de Microsoft. En mettant l'accent sur l'utilisation pratique et l'intégration des outils de la suite Office 365, cette formation permettra aux participants de maximiser l'efficacité et la productivité de leurs équipes. Le parcours de formation est structuré pour développer une compréhension approfondie de chaque application tout en mettant en lumière les meilleures pratiques de collaboration en ligne.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Plateforme collaborative Microsoft Office 365.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Maîtrise pack office 365",
        content: "Vous apprendrez à utiliser OneDrive pour stocker et organiser vos documents personnels, Teams pour la communication et la collaboration en temps réel, et SharePoint pour la gestion avancée des documents et la création de sites intranet collaboratifs.",
        color: "#E0EEF7",
      },
      {
        title: "Communication en ligne efficace",
        content: "Vous acquerrez des compétences pour tirer pleinement parti des fonctionnalités de visioconférence et de messagerie instantanée d'Office 365, ce qui vous permettra de maintenir une communication fluide et réactive, que ce soit en tête-à-tête ou en réunion d'équipe.",
        color: "#D3EEDC",
      },
      {
        title: "Utilisation optimisée de l'interface Office 365",
        content: "Vous saurez naviguer avec aisance dans l'interface utilisateur d'Office 365, en personnalisant votre espace de travail et en utilisant efficacement les applications intégrées pour une meilleure synergie de travail.",
        color: "#fff4d9",
      },
      {
        title: "Outils d'organisation personnelle et d'équipe",
        content: "Vous serez capable de mettre en œuvre les outils d'organisation comme Outlook, Planner et To Do pour gérer votre temps et vos tâches, ainsi que pour coordonner les projets d'équipe, garantissant ainsi que tous les membres restent alignés et concentrés sur les objectifs communs.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Maitriser-le-langage-Python',
    title: "Maitriser le langage Python",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Maîtriser le langage Python est un cursus complet destiné à ceux qui souhaitent se plonger dans le monde de la programmation et acquérir une maîtrise solide de l'un des langages de programmation les plus populaires et les plus puissants. Que vous soyez débutant ou que vous ayez déjà quelques notions de programmation, ce programme est conçu pour vous apporter une compréhension approfondie de Python, depuis les bases jusqu'aux concepts avancés. En mettant l'accent sur l'apprentissage pratique, cette formation vous mettra sur la voie de l'expertise en Python.",
    nbHeure: "14 Heures (2 jours)",
    src: "/images/Maitriser le langage Python.png",
    options: [
      {
        title: "14 Heures (2 jours)",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Fondamentaux de Python",
        content: "Vous acquerrez une compréhension solide de la syntaxe et de la structure de Python. Vous serez capable de lire, écrire et déboguer des scripts Python avec aisance, en posant une fondation robuste pour des études plus avancées.",
        color: "#E0EEF7",
      },
      {
        title: "Manipulation de données",
        content: "Vous apprendrez à utiliser des variables, des types de données et des opérateurs pour stocker, récupérer et manipuler des informations. Vous explorerez comment Python peut être utilisé pour traiter des données complexes et effectuer des calculs.",
        color: "#D3EEDC",
      },
      {
        title: "Structures de contrôle",
        content: "Vous maîtriserez les boucles et les instructions conditionnelles pour contrôler le flux d'exécution des programmes. Vous apprendrez à construire des programmes logiques et efficaces capables de prendre des décisions et de répéter des opérations.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Transaction-immobilière',
    title: "Transaction immobilière",
    priceActual: "299 €",
    category: "",
    categoryText: "",
    description: "La formation Transaction immobilière : Loi ALUR 42h est spécialement conçue pour les professionnels de l'immobilier qui souhaitent aiguiser leur expertise conformément à la réglementation en vigueur. Cette formation exhaustive aborde tous les aspects critiques de la transaction immobilière, depuis les obligations légales de l'agent immobilier jusqu'aux nuances des procédures de commercialisation, en passant par la gestion des mandats et la finalisation des ventes. Les participants seront guidés à travers les dernières mises à jour législatives et les meilleures pratiques du secteur, leur permettant de naviguer avec assurance dans le paysage réglementaire complexe de l'immobilier.",
    nbHeure: "42 Heures",
    src: "/images/Transaction immobilière.png",
    options: [
      {
        title: "42 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Procédures de commercialisation",
        content: "Vous acquerrez une maîtrise approfondie des procédures de commercialisation d'un bien immobilier, de la mise en marché jusqu'à la concrétisation de la vente, en veillant à la satisfaction des parties prenantes.",
        color: "#E0EEF7",
      },
      {
        title: "Obligations de l'agent immobilier",
        content: "Vous apprendrez à identifier et appliquer les obligations réglementaires spécifiques à la profession d'agent immobilier, en respectant scrupuleusement les cadres éthiques et légaux définis par la loi ALLUR.",
        color: "#D3EEDC",
      },
      {
        title: "Gestion des mandats",
        content: "Vous saurez différencier et gérer les divers types de mandats immobiliers, en comprenant les implications légales et pratiques de chaque catégorie pour optimiser votre portefeuille de biens.",
        color: "#fff4d9",
      },
      {
        title: "Processus de vente",
        content: "Vous serez capable de conduire avec compétence toutes les étapes des rendez-vous vendeurs, de l'offre d'achat jusqu'à la signature du compromis et de la promesse de vente, en garantissant transparence et sécurité juridique pour tous les intervenants.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Bilan-de-Compétences',
    title: "Bilan de Compétences : Bilan d’orientation",
    priceActual: "1650 €",
    category: "",
    categoryText: "",
    description: "La formation Bilan de Compétences : Bilan d'orientation est conçue pour vous permettre de prendre un moment réfléchi pour analyser et évaluer vos compétences, vos motivations et vos aspirations professionnelles. Ce parcours introspectif vous guidera à travers une exploration approfondie de vos atouts et vous aidera à tracer une voie claire pour votre avenir professionnel, que ce soit en changeant de voie, en évoluant dans votre carrière actuelle ou en planifiant des études supplémentaires.",
    nbHeure: "24 Heures",
    src: "/images/Bilan de Compétences Bilan d’orientation.png",
    options: [
      {
        title: "24 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Exploration de compétences",
        content: "Vous apprendrez à réaliser une auto-évaluation détaillée de vos compétences et aptitudes, à la fois personnelles et professionnelles. Cette réflexion vous aidera à mettre en lumière vos talents uniques et à identifier les domaines dans lesquels vous excellez, vous fournissant une base solide pour votre développement futur.",
        color: "#E0EEF7",
      },
      {
        title: "Définition de projet professionnel",
        content: "Vous acquerrez les outils et techniques nécessaires pour définir clairement votre projet professionnel. En évaluant vos intérêts et motivations, vous serez en mesure d'élaborer un plan d'action cohérent qui aligne vos aspirations professionnelles avec les opportunités du marché.",
        color: "#D3EEDC",
      },
      {
        title: "Stratégie de négociation",
        content: "Vous saurez utiliser vos compétences et atouts nouvellement identifiés comme levier dans la négociation d'opportunités d'emploi, de formation ou d'avancement de carrière. Cette capacité à communiquer efficacement votre valeur sera un atout considérable dans le monde professionnel concurrentiel.",
        color: "#fff4d9",
      },
      {
        title: "Planification de carrière",
        content: "Vous serez capable de développer une stratégie de carrière proactive, en utilisant les connaissances acquises sur vous-même pour prendre des décisions éclairées concernant votre parcours professionnel. Que ce soit pour une réorientation, une formation complémentaire ou une progression dans votre domaine actuel, vous aurez une vision claire et un plan pour y parvenir.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Langue-des-signes-LSF',
    title: "Langue des signes LSF",
    priceActual: "520 €",
    category: "",
    categoryText: "",
    description: "La formation Langue des signes LSF est un parcours d'apprentissage conçu pour vous initier à la communication en langue des signes française (LSF). Cette formation est idéale pour toute personne souhaitant se rapprocher de la communauté sourde, en comprendre les nuances culturelles et historiques, et briser les barrières de communication. Grâce à une méthode immersive et interactive, vous développerez non seulement une compétence linguistique, mais aussi une meilleure appréhension de l'inclusion sociale.",
    nbHeure: "30 Heures",
    src: "/images/Langue des signes LSF.png",
    options: [
      {
        title: "30 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Découverte de la culture sourde",
        content: "Vous découvrirez l'importance de la surdité et vous serez immergé dans l'histoire et la culture sourde, vous offrant une perspective enrichissante et essentielle pour comprendre le contexte dans lequel la LSF s'est développée et est utilisée aujourd'hui.",
        color: "#E0EEF7",
      },
      {
        title: "Présentation personnelle",
        content: " Vous apprendrez à vous présenter et à comprendre les présentations simples, une compétence de base cruciale pour toute interaction. Cela inclut l'expression de détails personnels tels que votre nom, votre âge, et votre profession de manière claire et compréhensible en LSF.",
        color: "#D3EEDC",
      },
      {
        title: "Communication de base",
        content: "Vous serez capable de produire des phrases et des expressions simples pour interagir dans des contextes familiers. Vous pourrez poser et répondre à des questions sur des sujets personnels comme la famille, le lieu d'habitation et le métier, en utilisant un vocabulaire adapté et des structures linguistiques de base.",
        color: "#fff4d9",
      },
      {
        title: "Interactions quotidiennes",
        content: "Vous maîtriserez les compétences nécessaires pour mener des conversations simples sur des sujets courants tels que les activités quotidiennes et les dates importantes. Vous serez capable d'établir une communication de base avec une autre personne en LSF, ce qui est un pas fondamental vers une communication plus fluide.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Français',
    title: "Français",
    priceActual: "1490 €",
    category: "",
    categoryText: "",
    description: "La formation Français est spécialement conçue pour les professionnels non francophones qui souhaitent maîtriser le français dans un contexte professionnel. Ce programme pédagogique complet permettra aux participants de comprendre, rédiger et communiquer efficacement en français dans leurs activités quotidiennes au travail. À travers des modules interactifs et pratiques, les apprenants seront guidés vers une maîtrise approfondie du français professionnel.",
    nbHeure: "105 Heures",
    src: "/images/Français.png",
    options: [
      {
        title: "105 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en français, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances du français professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Anglais',
    title: "Anglais",
    priceActual: "1150 €",
    category: "",
    categoryText: "",
    description: "La formation Anglais Professionnel s'adresse à ceux qui souhaitent maîtriser l'anglais dans un contexte professionnel. Elle vous ouvre les portes d'une communication internationale efficace, essentielle dans le monde des affaires globalisé d'aujourd'hui. Que vous débutiez ou cherchiez à perfectionner votre anglais, cette formation vous apportera les compétences nécessaires pour comprendre, rédiger et échanger en anglais avec aisance et précision.",
    nbHeure: "200 Heures",
    src: "/images/Anglais.png",
    options: [
      {
        title: "200 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en Anglais, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances de l'anglais professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Allemand',
    title: "Allemand",
    priceActual: "1150 €",
    category: "",
    categoryText: "",
    description: "La formation Allemand Professionnel s'adresse à ceux qui souhaitent maîtriser l'Allemand dans un contexte professionnel. Elle vous ouvre les portes d'une communication internationale efficace, essentielle dans le monde des affaires globalisé d'aujourd'hui. Que vous débutiez ou cherchiez à perfectionner votre allemand, cette formation vous apportera les compétences nécessaires pour comprendre, rédiger et échanger en Allemand avec aisance et précision.",
    nbHeure: "200 Heures",
    src: "/images/Allemand.png",
    options: [
      {
        title: "200 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en Allemand, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances de l'Allemand professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Espagnol',
    title: "Espagnol",
    priceActual: "1150 €",
    category: "",
    categoryText: "",
    description: "La formation Espagnol Professionnel s'adresse à ceux qui souhaitent maîtriser l'Espagnol dans un contexte professionnel. Elle vous ouvre les portes d'une communication internationale efficace, essentielle dans le monde des affaires globalisé d'aujourd'hui. Que vous débutiez ou cherchiez à perfectionner votre Espagnol, cette formation vous apportera les compétences nécessaires pour comprendre, rédiger et échanger en Espagnol avec aisance et précision.",
    nbHeure: "200 Heures",
    src: "/images/Espagnol.png",
    options: [
      {
        title: "200 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en Espagnol, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances de l'Espagnol professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Portugais',
    title: "Portugais",
    priceActual: "1150 €",
    category: "",
    categoryText: "",
    description: "La formation Portugais Professionnel s'adresse à ceux qui souhaitent maîtriser le Portugais dans un contexte professionnel. Elle vous ouvre les portes d'une communication internationale efficace, essentielle dans le monde des affaires globalisé d'aujourd'hui. Que vous débutiez ou cherchiez à perfectionner votre Portugais, cette formation vous apportera les compétences nécessaires pour comprendre, rédiger et échanger en Portugais avec aisance et précision.",
    nbHeure: "200 Heures",
    src: "/images/Portugais.png",
    options: [
      {
        title: "200 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en Portugais, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances du Portugais professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
  {
    id: 'Néerlandais',
    title: "Néerlandais",
    priceActual: "1150 €",
    category: "",
    categoryText: "",
    description: "La formation Néerlandais Professionnel s'adresse à ceux qui souhaitent maîtriser le Néerlandais dans un contexte professionnel. Elle vous ouvre les portes d'une communication internationale efficace, essentielle dans le monde des affaires globalisé d'aujourd'hui. Que vous débutiez ou cherchiez à perfectionner votre Néerlandais, cette formation vous apportera les compétences nécessaires pour comprendre, rédiger et échanger en Néerlandais avec aisance et précision.",
    nbHeure: "200 Heures",
    src: "/images/Néerlandais.png",
    options: [
      {
        title: "200 Heures",
        icon: "clock",
        color: "#92A5EF",
      },
      {
        title: "Lieu : Distanciel",
        icon: "location",
        color: "#8BC5E5",
      },
      {
        title: "Public : Manager de tout secteur",
        icon: "user",
        color: "#FA8F54",
      },
      {
        title: "Documentation : Oui",
        icon: "at",
        color: "#58C27D",
      },
    ],
    competences: [
      {
        title: "Compréhension de documents professionnels",
        content: "Vous apprendrez à déchiffrer et comprendre avec précision tout type de document professionnel en Néerlandais, qu'il s'agisse de consignes, d'articles spécialisés, de rapports techniques ou d'autres écrits pertinents à votre secteur d'activité.",
        color: "#E0EEF7",
      },
      {
        title: "Rédaction claire et professionnelle",
        content: "Vous acquerrez les compétences nécessaires pour rédiger des e-mails, des synthèses, des rapports et des comptes-rendus de manière structurée et claire, en respectant les conventions et les nuances du Néerlandais professionnel.",
        color: "#D3EEDC",
      },
      {
        title: "Communication écrite en milieu professionnel",
        content: "Vous saurez engager des échanges écrits avec assurance dans différentes situations professionnelles, en vous adaptant au ton et au style appropriés pour une communication efficace avec vos collègues, clients ou partenaires.",
        color: "#fff4d9",
      },
    ],
  },
];

export const stays1 = [];
export const stays2 = [];

