// Carousel data service
import { pb } from './pocketbase';

const getImageUrl = (record) => {
  try {
    if (!record?.image) return '';
    // Construction directe de l'URL
    return `${pb.baseUrl}/api/files/${record.collectionId}/${record.id}/${encodeURIComponent(record.image)}`;
  } catch (error) {
    console.error('Error generating URL:', error);
    return '';
  }
};

export const getCarouselSlides = async () => {
  try {
    const records = await pb.collection('carrousel').getFullList({
      sort: 'ordre',
    });
    
    // Log pour debug
    if (records.length > 0) {
      console.log('Premier enregistrement:', records[0]);
      const imageUrl = getImageUrl(records[0]);
      console.log('URL de l\'image:', imageUrl);
      
      // Test de l'existence de l'image
      try {
        const response = await fetch(imageUrl, { method: 'HEAD' });
        console.log('Statut de l\'image:', response.status);
      } catch (error) {
        console.error('Erreur lors du test de l\'image:', error);
      }
    }

    return records.map(record => ({
      id: record.id,
      title: record.title,
      description: record.description,
      image: getImageUrl(record),
      imageMobile: getImageUrl(record),
      buttonText: record.buttonText,
      buttonLink: record.buttonLink,
      ordre: record.ordre
    }));
  } catch (error) {
    console.error('Error fetching carousel data:', error);
    return [];
  }
};

export const getSlideById = async (id) => {
  try {
    const record = await pb.collection('carrousel').getOne(id);
    return {
      id: record.id,
      title: record.title,
      description: record.description,
      image: getImageUrl(record),
      imageMobile: getImageUrl(record),
      buttonText: record.buttonText,
      buttonLink: record.buttonLink,
      ordre: record.ordre
    };
  } catch (error) {
    console.error('Error fetching slide:', error);
    return null;
  }
};
