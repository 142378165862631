import React from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "../Control";
import Icon from "../Icon";
import Actions from "../Actions";
import Gallery from "./Gallery";

const Product = ({
  classSection,
  title,
  gallery,
  alternance,
}) => {
  const isAlternance = alternance === "1";

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
        />
        <div className={styles.head}>
          <div className={styles.box}>
            <div className={styles.titleContainer}>
              <h1 className={cn("h2", styles.title)}>{title}</h1>
              {isAlternance && (
                <div className={styles.alternanceBadge}>
                  Alternance
                </div>
              )}
            </div>
            <div className={styles.accessibility}>
              <div className={styles.accessibilityBadge}>
                <Icon name="accessible" size="20" />
                <div className={styles.accessibilityText}>
                  Nos formations sont accessibles aux personnes en situation de handicap, pour toutes questions relatives, veuillez contacter notre référent handicap : Rachid TEBBOUB rtebboub@wake-up-success.fr
                </div>
              </div>
            </div>
          </div>
          <Actions className={styles.actions} />
        </div>
        <Gallery className={styles.gallery} items={gallery} type="tour" />
      </div>
    </div>
  );
};

export default Product;
