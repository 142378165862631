import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Product from "../../components/Product";
import Description from "./Description";
import Destinations from "../../components/Destinations";

// data
import { getFormationById, getTopFormations } from "../../services/formation.service";

const StaysProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [formations, setFormations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log('ID reçu:', id);
      const [formationData, formationsData] = await Promise.all([
        getFormationById(id),
        getTopFormations()
      ]);
      
      if (formationData) {
        console.log('Formation trouvée:', formationData);
        setProduct(formationData);
      } else {
        console.log('Formation non trouvée pour ID:', id);
      }
      setFormations(formationsData);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!product) {
    // Afficher au moins les top formations si la formation principale n'est pas trouvée
    return (
      <>
        <div className="container">
          <div className="alert alert-warning">
            Formation non trouvée
          </div>
        </div>
        <Destinations items={formations} />
      </>
    );
  }

  // Déterminer si c'est une formation en alternance
  const isAlternance = product.alternance === "1";
  const isNotAlternance = product.alternance === "0" || !product.alternance;

  return (
    <>
      <Product
        classSection="section-mb64"
        title={product.title}
        gallery={product.gallery || []}
        alternance={product.alternance}
      />
      <Description 
        classSection="section" 
        options={product.options} 
        description={product.description} 
        descriptions={product.descriptions || []}
        competences={product.competences}
        price={product.priceActual}
        alternance={product.alternance}
        isAlternance={isAlternance}
        isNotAlternance={isNotAlternance}
      />
      <Destinations items={formations} />
    </>
  );
};

export default StaysProduct;
