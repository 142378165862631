import React from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import Icon from "../Icon";
import { Link } from "react-router-dom";

const Receipt = ({
  className,
  items,
  children,
  priceOld,
  priceActual,
  time,
}) => {
  return (
    <div className={cn(className, styles.receipt)}>
      <div className={styles.head}>
        <div className={styles.details}>
          <div className={styles.cost}>
            <div className={styles.old}>{priceOld}</div>
            <div className={styles.actual}>{priceActual}</div>
            <div className={styles.note}>/{time}</div>
          </div>
          <div className={styles.item} ></div>
        </div>
      </div>
      <div className={cn(styles.description)}>
        <div className={styles.item} >
          <Link className={cn("button", styles.button)} to="/contact">
            Contacter
          </Link>
        </div>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Receipt;
