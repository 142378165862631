import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Image from "../Image";

const Header = ({ separatorHeader, wide, notAuthorized }) => {
    const [visibleNav, setVisibleNav] = useState(false);
    const [visible, setVisible] = useState(false);
    const { pathname } = useLocation();

    return (
        <>
            <div
                className={cn(
                    styles.header,
                    { [styles.headerBorder]: separatorHeader },
                    { [styles.wide]: wide }
                )}
            >
                <div className={cn("container", styles.container)}>
                    <Link className={styles.logo} to="/">
                        <Image
                            className={styles.pic}
                            src="/images/Wake Up Success.webp"
                            srcDark="/images/Wake Up Success.webp"
                            alt="Fleet"
                        />
                    </Link>
                    <div
                        className={cn(styles.wrapper, {
                            [styles.active]: visibleNav,
                        })}
                    >

                        <NavLink
                            className={cn(styles.link, {
                                [styles.active]: pathname === "/formations",
                            })}
                            to="/"
                        >
                            Accueil
                        </NavLink>
                        <NavLink
                            className={cn(styles.link, {
                                [styles.active]: pathname === "/support",
                            })}
                            to="/formations"
                        >
                            Nos formations
                        </NavLink>
                        <NavLink
                            className={cn(styles.link, {
                                [styles.active]: pathname === "/support",
                            })}
                            to="/about"
                        >
                            En savoir plus
                        </NavLink>



                        <NavLink
                            className={cn(
                                "button-stroke button-small",
                                styles.button,

                            )}
                            to="/contact"
                        >
                            Contacter
                        </NavLink>
                    </div>

                    <button
                        className={cn(styles.burger, {
                            [styles.active]: visibleNav,
                        })}
                        onClick={() => setVisibleNav(!visibleNav)}
                    ></button>
                </div>
            </div>

        </>
    );
};

export default Header;
