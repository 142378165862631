import { pb } from './pocketbase';

export const getCategories = async () => {
    try {
        const records = await pb.collection('categorie').getFullList();
        return records.map(record => ({
            id: record.id,
            title: record.title,
            content: record.content,
            counter: record.counter,
            counterAlternance: record.counterAlternance,
            icon: record.icon,
            url: record.url
        }));
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};