import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import { getCarouselSlides } from "../../../services/carousel.services";

const Main = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        setLoading(true);
        const carouselSlides = await getCarouselSlides();
        setSlides(carouselSlides);
      } catch (error) {
        console.error('Error loading carousel slides:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSlides();
  }, []);

  useEffect(() => {
    if (slides.length === 0) return;

    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => 
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [slides]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    setImageError(false);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  if (loading) {
    return (
      <div className={cn("section-mb80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.preview}>
            <div className={styles.wrap}>
              <div className={styles.loading}>Chargement...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!slides.length) {
    return (
      <div className={cn("section-mb80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.preview}>
            <div className={styles.wrap}>
              <div className={styles.error}>Aucune donnée disponible</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const currentSlideData = slides[currentSlide];

  return (
    <div className={cn("section-mb80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          {!imageError ? (
            <picture>
              <source
                media="(max-width: 500px)"
                srcSet={currentSlideData.imageMobile}
              />
              <img 
                src={currentSlideData.image} 
                alt={currentSlideData.title}
                onError={handleImageError}
                className={styles.slideImage}
              />
            </picture>
          ) : (
            <div className={styles.imageError}>
              <div className={styles.errorMessage}>
                Image non disponible
              </div>
            </div>
          )}
          <div className={styles.wrap}>
            <h1 className={cn("hero", styles.title)}>{currentSlideData.title}</h1>
            <div className={cn("info", styles.info)}>
              {currentSlideData.description}
            </div>
            <Link 
              className={cn("button", styles.button)} 
              to={currentSlideData.buttonLink}
            >
              {currentSlideData.buttonText}
            </Link>
          </div>
          <div className={styles.navigationContainer}>
            <div className={styles.dots}>
              {slides.map((_, index) => (
                <button
                  key={index}
                  className={cn(styles.dot, {
                    [styles.active]: currentSlide === index,
                  })}
                  onClick={() => handleSlideChange(index)}
                  aria-label={`Slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
