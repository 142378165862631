import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "Général",
    items: [
      {
        title: "Qu'est-ce que Wake Up Success ?",
        content:
          "Wake Up Success est une école de formation professionnelle située au cœur de Lille, dans la région lilloise. Nous proposons une gamme variée de formations pour les professionnels et les particuliers, visant à améliorer les compétences et à se perfectionner dans le monde du travail.",
      },
      {
        title: "Où est située votre école de formation ?",
        content:
          "Notre école est située au cœur de Lille, dans la région lilloise. Vous pouvez trouver notre emplacement exact sur notre site web.",
      },
      {
        title: "Quels types de formations proposez-vous pour les professionnels ?",
        content:
          "Nous proposons une variété de formations professionnelles dans des domaines tels que la gestion, la sécurité, la comptabilité, et bien d'autres encore. Consultez notre catalogue pour découvrir nos offres.",
      },
      {
        title: "Quelles sont les horaires d'ouverture de Wake Up Success ?",
        content:
          "Nos horaires d'ouverture varient en fonction des jours de la semaine et des campus spécifiques. Vous pouvez trouver les horaires détaillés sur notre site web ou en nous contactant directement.",
      },
      {
        title: "Les formations de Wake Up Success sont-elles adaptées aux professionnels en reconversion ?",
        content:
          "Absolument ! Nous proposons des formations spécialement conçues pour les professionnels en reconversion, visant à développer de nouvelles compétences dans des domaines différents de leur carrière précédente.",
      },
    ],
  },
  {
    title: "Formations ",
    items: [
      {
        title: "Quelles sont les formations disponibles pour les professionnels ?",
        content:
          "Notre école propose une gamme complète de formations professionnelles, allant de la gestion d'entreprise aux premiers secours en passant par la comptabilité et bien d'autres. Vous trouverez plus de détails dans notre catalogue de formations.",
      },
      {
        title: "Les formations délivrent-elles des certifications reconnues ?",
        content:
          "Oui, la plupart de nos formations aboutissent à la délivrance de certifications reconnues dans leurs domaines respectifs.",
      },
      {
        title: "Proposez-vous des formations sur mesure pour les entreprises ?",
        content:
          "Oui, nous proposons des formations sur mesure pour les entreprises qui souhaitent développer les compétences de leur personnel. Contactez-nous pour discuter de vos besoins spécifiques.",
      }
    ],
  },
  {
    title: "Inscription",
    items: [
      {
        title: "Comment puis-je m'inscrire à une formation chez Wake Up Success ?",
        content:
          "Vous pouvez vous inscrire en ligne en suivant les instructions sur la page de la formation qui vous intéresse. Si vous avez besoin d'assistance, n'hésitez pas à nous contacter.",
      },
      {
        title: "Puis-je utiliser mon CPF pour financer ma formation ?",
        content:
          ' Oui, Wake Up Success est éligible au CPF. Nous vous assisterons tout au long du processus de demande de financement.',
      },
      {
        title: "Quels sont les documents requis pour l'inscription à une formation ?",
        content:
          "Les documents requis varient en fonction de la formation. En général, nous demandons une pièce d'identité, un CV et des relevés de notes ou d'autres documents pertinents. Les détails spécifiques sont fournis lors de l'inscription.",
      }
    ],
  },
  {
    title: "Cours",
    items: [
      {
        title: " Comment se déroulent les cours à Wake Up Success ?",
        content:
          'Nos cours peuvent être dispensés en ligne ou en présentiel, selon votre préférence. Les informations spécifiques sont disponibles sur la page de chaque formation.',
      },
      {
        title: " Y a-t-il des prérequis pour les cours ?",
        content:
          'Les prérequis varient en fonction de la formation. Vous trouverez des informations détaillées sur les prérequis sur les pages de chaque cours.',
      }
    ],
  },
];

const Faq = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>FAQ</h2>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
