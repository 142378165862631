import { pb } from './pocketbase';

// Fonction utilitaire pour formater une formation pour l'affichage
const formatFormationForDisplay = (record) => {
    return {
        id: record.id,
        idFormation: record.idFormation,
        title: record.title,
        content: record.littleDescription,
        src: record.imagePrimary ? pb.getFileUrl(record, record.imagePrimary) : '',
        srcSet: record.imagePrimary ? pb.getFileUrl(record, record.imagePrimary) : '',
        promo: record.alternance ? "Alternance" : null,
        category: "black"
    };
};

export const getTopFormations = async () => {
    try {
        const records = await pb.collection('formation').getFullList({
            sort: '-created',
            filter: 'topFormation = true',
            expand: 'category'
        });
        
        return records.map(formatFormationForDisplay);
    } catch (error) {
        console.error('Error fetching top formations:', error);
        return [];
    }
};

export const getAllFormations = async () => {
    try {
        const records = await pb.collection('formation').getFullList({
            sort: '-created',
            expand: 'category'
        });
        
        return records.map(record => ({
            id: record.id,
            idFormation: record.idFormation,
            title: record.title,
            src: pb.files.getUrl(record, record.imagePrimary),
            nbHeure: record.nbHeure,
            priceActual: record.priceActual,
            alternance: record.alternance ? "1" : "0",
            categoryId: record.expand?.category?.id,
            categoryTitle: record.expand?.category?.title
        }));
    } catch (error) {
        console.error('Error fetching all formations:', error);
        return [];
    }
};

export const getAllCategories = async () => {
    try {
        const records = await pb.collection('categorie').getFullList({
            sort: 'title'
        });
        
        return records.map(record => ({
            id: record.id,
            title: record.title
        }));
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};

// Fonction utilitaire pour nettoyer le HTML
const cleanHtmlContent = (html) => {
    if (!html) return '';
    
    // Remplacer les entités HTML courantes
    const replacements = {
        '&agrave;': 'à',
        '&eacute;': 'é',
        '&egrave;': 'è',
        '&nbsp;': ' ',
        '&rsquo;': "'",
        '&ecirc;': 'ê',
        '&ccedil;': 'ç'
    };

    // Enlever les balises HTML et remplacer les entités
    let text = html.replace(/<[^>]*>/g, '')
        .replace(/&[^;]+;/g, match => replacements[match] || match);
    
    // Nettoyer les espaces multiples et les retours à la ligne
    return text.replace(/\s+/g, ' ').trim();
};

export const getFormationById = async (id) => {
    try {
        console.log('Recherche de la formation avec ID:', id);
        
        // Récupérer la formation directement avec son ID
        const record = await pb.collection('formation').getOne(id, {
            expand: 'category,options,competences'
        });

        console.log('Formation trouvée:', record);

        if (!record) {
            throw new Error('Formation non trouvée');
        }

        // Create gallery array with primary image first, followed by secondary images
        const gallery = [
            record.imagePrimary ? pb.getFileUrl(record, record.imagePrimary) : '',
            ...(record.imageSecondary || []).map(img => pb.getFileUrl(record, img))
        ].filter(url => url); // Filtrer les URLs vides

        // Format options and competences from the expanded relations
        const options = record.expand?.options?.map(option => ({
            id: option.id,
            title: option.title,
            icon: option.icon || 'info',
            color: option.color || '#000000',
        })) || [];

        const competences = record.expand?.competences?.map(competence => ({
            id: competence.id,
            title: competence.title,
            description: competence.description || '',
            color: competence.color || '#D3EEDC'
        })) || [];

        return {
            id: record.id,
            idFormation: record.idFormation,
            title: record.title,
            description: cleanHtmlContent(record.description),
            descriptions: record.descriptions?.map(cleanHtmlContent) || [],
            competences: competences,
            priceActual: record.priceActual,
            alternance: record.alternance ? "1" : "0",
            options: options,
            gallery: gallery,
            categoryId: record.expand?.category?.id,
            categoryTitle: record.expand?.category?.title
        };
    } catch (error) {
        console.error('Error fetching formation:', error);
        return null;
    }
};