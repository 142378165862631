import React from "react";
import cn from 'classnames';
import Main from "./Main";
import styles from "./Cars.module.sass"



const Cars = () => {
  return (
    <>
      <Main />
      <div className={cn(styles.section)}>
        <div className="container">
          <h2>Article 1 :</h2>
          <p>Le présent est rédigé conformément au décret 2019-1143 du 07 novembre 2019 relatif aux dispositions spécifiques applicables aux centres de formation d'apprentis et aux obligations des organismes prestataires d'actions de développement des compétences.
            Il obéit aux dispositions des articles L.6352-3 et 5 et R.6352-1 à 15 du Code du Travail.<br />
            Le présent règlement s'applique à tous les stagiaires, et ce pour la durée de la formation suivie</p>
          <br />
          <h2>Article 2 : HYGIENE ET SECURITE</h2>
          <p>La prévention des risques d'accidents et de maladies est impérative et exige de chacun le respect total de toutes les prescriptions applicables en matière d'hygiène et de sécurité. A cet effet, les consignes générales et particulières de sécurité en vigueur dans l'organisme, lorsqu'elles existent, doivent être strictement respectées sous peine de sanctions disciplinaires.</p>
          <br />
          <h2>Article 3 : DISCIPLINE GENERALE</h2>
          <p>Il est formellement interdit aux stagiaires d'entrer dans l'établissement en état d'ivresse, d'introduire des boissons alcoolisées dans les locaux, de quitter le stage sans motif, d'emporter aucun objet sans autorisation écrite.</p>
          <br />
          <h2>Article 4 : SANCTIONS</h2>
          <p>Tout agissement considéré comme fautif par le directeur de l'organisme de formation ou son représentant pourra, en fonction de sa nature et de sa gravité, faire l'objet de l'une ou l'autre des sanctions ci-après par ordre d'importance: avertissement écrit par le Directeur de l'organisme de formation ou par son représentant; blâme, exclusion définitive de la formation.</p>
          <br />
          <h2>Article 5 : GARANTIES DISCIPLINAIRES</h2>
          <p>Aucune sanction ne peut être infligée au stagiaire ou à l'apprenti sans que celui-ci ait été informé au préalable des griefs retenus contre lui.</p>
          <br />
          <h2>Article 6 : </h2>
          <p>Lorsque le directeur de l'organisme de formation ou son représentant envisage de prendre une sanction qui a une incidence, immédiate ou non, sur la présence d'un stagiaire dans une formation, il est procédé comme suit:
            1o Le directeur ou son représentant convoque le stagiaire en lui indiquant l'objet de cette convocation. Celle-ci précise la date, l'heure et le lieu de l'entretien. Elle est écrite et est adressée par lettre recommandée ou remise à l'intéressé contre décharge;
            2° Au cours de l'entretien, le stagiaire ou l'apprenti peut se faire assister par la personne de son choix, notamment le délégué de stage. La convocation mentionnée au 1° fait état de cette faculté ;
            3o Le directeur ou son représentant indique le motif de la sanction envisagée et recueille les explications du stagiaire.
          </p>
          <br />
          <h2>Article 7 :</h2>
          <p>Au cours de l'entretien, le stagiaire peut se faire assister par une personne de son choix, stagiaire ou salarié de l'organisme. La convocation mentionnée à l'article précédent fait état de cette faculté. Lors de l'entretien, le motif de la sanction envisagée est indiqué au stagiaire, dont on recueille les explications.</p>
          <br />
          <h2>Article 8 :</h2>
          <p>La sanction ne peut intervenir moins d'un jour franc ni plus de quinze jours après l'entretien.
            Elle fait l'objet d'une décision écrite et motivée, notifiée au stagiaire par lettre recommandée ou remise contre récépissé.</p>
          <br />
          <h2>Article 9 :</h2>
          <p>La sanction ne peut intervenir moins d'un jour franc ni plus de quinze jours après l'entretien.
            Elle fait l'objet d'une décision écrite et motivée, notifiée au stagiaire par lettre recommandée ou remise contre </p>
          <br />
          <h2>Article 10 :</h2>
          <p>Le directeur de l'organisme de formation informe l'employeur et l'organisme financeur de la sanction prise.</p>
          <br />
          <h2>Article 11 : REPRESENTATION DES STAGIAIRES</h2>
          <p>Pour chacun des stages d'une durée supérieure à 500 heures, il est procédé simultanément à l'élection d'un délégué titulaire et d'un délégué suppléant en scrutin uninominal à deux tours.</p>
          <br />
          <h2>Article 12 :</h2>
          <p>Le scrutin se déroule pendant les heures de la formation. Il a lieu au plus tôt vingt heures et au plus tard quarante heures après le début de la première session collective.
            Le directeur de l'organisme de formation est responsable de l'organisation du scrutin. Il en assure le bon déroulement.
            Lorsque, à l'issue du scrutin, il est constaté que la représentation des stagiaires et apprentis ne peut être assurée, le directeur dresse un procès-verbal de carence.
          </p>
          <br />
          <h2>Article 13 :</h2>
          <p>Les délégués sont élus pour la durée de la formation. Leurs fonctions prennent fin lorsqu'ils cessent de participer à la formation.
            Lorsque le délégué titulaire et le délégué suppléant ont cessé leurs fonctions avant la fin de la formation, il est procédé à une nouvelle élection, dans les conditions prévues aux articles R6352-9 à R6352-12 du code du travail.
          </p>
          <br />
          <h2>Article 14 :</h2>
          <p>Les délégués font toute suggestion pour améliorer le déroulement des formations et les conditions de vie des stagiaires et des apprentis dans l'organisme de formation.
            Ils présentent les réclamations individuelles ou collectives relatives à ces matières, aux conditions de santé et de sécurité au travail et à l'application du règlement intérieur.
          </p>
          <br />
          <h2>Article 15 : PUBLICITE DU REGLEMENT </h2>
          <p>Un exemplaire du présent règlement est remis à chaque stagiaire (avant toute inscription définitive).</p>
        </div>
      </div>
    </>
  );
};

export default Cars;
