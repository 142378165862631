import React, { useState, useEffect } from "react";
import Main from "./Main";
import Destinations from "../../components/Destinations";
import Services from "./Services";
import Faq from "./Faq";
import Categories from "../../components/Categories";
import Newsletter from "../../components/Newsletter";
import Glossaire from "./Glossaire";

// data
import { getCategories } from "../../services/categorie.service";
import { getTopFormations } from "../../services/formation.service";

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const [formations, setFormations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [categoriesData, formationsData] = await Promise.all([
        getCategories(),
        getTopFormations()
      ]);
      setCategories(categoriesData);
      setFormations(formationsData);
    };
    fetchData();
  }, []);

  return (
    <>
      <Main />
      <Categories
        classSection="section"
        title="Nos formations"
        info="Découvrez nos programmes de formation"
        items={categories}
      />
      <Destinations items={formations} />
      <Services />
      <Faq />
      <Glossaire />
      <Newsletter />
    </>
  );
};

export default HomePage;
