import React from "react";
import cn from "classnames";
import styles from "./Services.module.sass";
import Item from "./Item";

const services = [
  {
    image: "/images/content/smile.svg",
    title: "Méthodologie axée sur l'Évaluation",
    content:
      "À Wake Up Success, nous débutons chaque parcours de formation par une évaluation approfondie de vos besoins et compétences actuelles. Cette évaluation nous permet de personnaliser votre formation pour répondre précisément à vos objectifs professionnels, garantissant ainsi une expérience d'apprentissage sur mesure.",
  },
  {
    image: "/images/content/lightning.svg",
    title: "Méthodologie de Formation Pratique",
    content:
      "Chez Wake Up Success, nous privilégions l'apprentissage par la pratique. Nos formations vous immergent dans des scénarios professionnels concrets. Vous acquerrez des compétences pratiques que vous pourrez appliquer immédiatement dans votre travail grâce à des exercices, des études de cas et des simulations guidées par nos formateurs expérimentés.",
  },
  {
    image: "/images/content/gift.svg",
    title: "Méthodologie de Perfectionnement Continu",
    content:
      "Au-delà de votre formation initiale, Wake Up Success vous encourage à poursuivre votre développement professionnel. Nous offrons des opportunités de formation continue, des ateliers et des ressources pour maintenir votre expertise à jour. Notre engagement envers votre succès persiste tout au long de votre parcours professionnel.",
  },
];

const Services = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage", styles.stage)}>
            Notre Engagement envers Votre Réussite
          </div>
          <h2 className={cn("h2", styles.title)}>Notre pédagogie</h2>
        </div>
        <div className={styles.list}>
          {services.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
