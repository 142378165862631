import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from "./Catalog.module.sass";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";
import Dropdown from "../../../components/Dropdown";
import { getAllFormations, getAllCategories } from "../../../services/formation.service";

const INITIAL_LOAD_COUNT = 9;
const LOAD_MORE_COUNT = 3;

// Fonction pour convertir le prix en nombre
const getPriceNumber = (price) => {
  if (!price) return Infinity;
  if (typeof price === 'number') return price;
  if (price === "Devis") return Infinity;
  
  // Si c'est une chaîne, on enlève tout sauf les chiffres
  const priceString = String(price);
  const numericValue = parseInt(priceString.replace(/[^0-9]/g, ''));
  return isNaN(numericValue) ? Infinity : numericValue;
};

const Catalog = ({ searchQuery }) => {
  const [formations, setFormations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);
  const [loadIndex, setLoadIndex] = useState(INITIAL_LOAD_COUNT);
  const [formationType, setFormationType] = useState("Tous");
  const [selectedCategory, setSelectedCategory] = useState("Toutes");
  const [sortOrder, setSortOrder] = useState("Par défaut");
  const [loading, setLoading] = useState(true);

  const formationTypes = ["Tous", "Alternance", "Initial"];
  const sortOptions = ["Par défaut", "Prix croissant", "Prix décroissant"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [formationsData, categoriesData] = await Promise.all([
          getAllFormations(),
          getAllCategories()
        ]);
        setFormations(formationsData);
        setCategories([{ id: "all", title: "Toutes" }, ...categoriesData]);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let results = [...formations];

    // Filtre par catégorie
    if (selectedCategory !== "Toutes") {
      const selectedCategoryId = categories.find(cat => cat.title === selectedCategory)?.id;
      if (selectedCategoryId && selectedCategoryId !== "all") {
        results = results.filter(formation => formation.categoryId === selectedCategoryId);
      }
    }

    // Filtre par type de formation
    if (formationType === "Alternance") {
      results = results.filter(formation => formation.alternance === "1");
    } else if (formationType === "Initial") {
      results = results.filter(formation => formation.alternance === "0");
    }

    // Filtre par recherche
    if (searchQuery) {
      results = results.filter(formation => 
        formation.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Tri par prix
    if (sortOrder !== "Par défaut") {
      results.sort((a, b) => {
        const priceA = getPriceNumber(a.priceActual);
        const priceB = getPriceNumber(b.priceActual);
        return sortOrder === "Prix croissant" ? priceA - priceB : priceB - priceA;
      });
    }

    setFilteredFormations(results.slice(0, loadIndex));
  }, [searchQuery, formationType, sortOrder, loadIndex, formations, selectedCategory, categories]);

  const handleLoadMore = () => {
    setLoadIndex(prevIndex => prevIndex + LOAD_MORE_COUNT);
  };

  if (loading) {
    return <Loader />;
  }

  const hasMoreFormations = filteredFormations.length < formations.filter(formation => {
    // Appliquer les mêmes filtres que dans le useEffect
    if (selectedCategory !== "Toutes") {
      const selectedCategoryId = categories.find(cat => cat.title === selectedCategory)?.id;
      if (selectedCategoryId && selectedCategoryId !== "all" && formation.categoryId !== selectedCategoryId) {
        return false;
      }
    }
    if (formationType === "Alternance" && formation.alternance !== "1") return false;
    if (formationType === "Initial" && formation.alternance !== "0") return false;
    if (searchQuery && !formation.title.toLowerCase().includes(searchQuery.toLowerCase())) return false;
    return true;
  }).length;

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.header}>
            <h4 className={cn("h4", styles.title)}>
              {filteredFormations.length > 0 
                ? `Plus de ${formations.length} formations`
                : "Formations disponibles"}
            </h4>
            <div className={styles.filters}>
              <Dropdown
                className={styles.dropdown}
                value={selectedCategory}
                setValue={setSelectedCategory}
                options={categories.map(cat => cat.title)}
              />
              <Dropdown
                className={styles.dropdown}
                value={formationType}
                setValue={setFormationType}
                options={formationTypes}
              />
              <Dropdown
                className={styles.dropdown}
                value={sortOrder}
                setValue={setSortOrder}
                options={sortOptions}
              />
            </div>
          </div>
          <div className={styles.list}>
            {filteredFormations.length > 0 ? (
              filteredFormations.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))
            ) : (
              <div className={styles.empty}>
                Aucun résultat n'a été trouvé.
              </div>
            )}
          </div>
          {hasMoreFormations && filteredFormations.length > 0 && (
            <div className={styles.btns}>
              <button className={cn("button-stroke", styles.button)} onClick={handleLoadMore}>
                Charger plus
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
