import { Routes, Route } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "./styles/app.sass";

import Page from "./components/Page";

import Product from "./screens/Product";
import Contact from "./screens/ContactPage";
import Accueil from "./screens/HomePage";
import Formations from "./screens/FormationPage";
import About from "./screens/AboutPage";
import ConditionsG from "./screens/ConditionGeneralPage";
import ReglementG from "./screens/ReglementGeneralPage";

function App() {
    return (
        <Routes>
            <Route path="/">
                <Route
                    index
                    element={
                        <Page>
                            <Accueil />
                        </Page>
                    }
                />
                <Route
                    path="/about"
                    element={
                        <Page>
                            <About />
                        </Page>
                    }
                />
               
                <Route
                    path="formations"
                    element={
                        <Page>
                            <Formations />
                        </Page>
                    }
                />
                <Route
                    path="/formation/:id"
                    element={
                        <Page separatorHeader>
                            <Product />
                        </Page>
                    }
                />
                <Route
                    path="contact"
                    element={
                        <Page separatorHeader>
                            <Contact />
                        </Page>
                    }
                />
                 <Route
                    path="condition-générales"
                    element={
                        <Page separatorHeader>
                            <ConditionsG />
                        </Page>
                    }
                />
                 <Route
                    path="réglement-Intérieur"
                    element={
                        <Page separatorHeader>
                            <ReglementG />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}

export default App;
