import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";

const Item = ({ className, item }) => {
  return (
    <Link
      className={cn(
        className,
        styles.card,
      )}
      to={`/formation/${item.id}`}
    >
      <div className={styles.preview}>
        {item.alternance === "1" && (
          <div className={cn("status status-black", styles.badge)}>
            Alternance
          </div>
        )}
        <img src={item.src} alt="Formations" loading="lazy" />
      </div>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.title}>{item.title}</div>
        </div>
        <div className={styles.foot}>
          <div className={styles.flex}>
            <div className={styles.nbHeure}>{item.nbHeure} Heures</div>
            <div className={styles.price}>
              <div className={styles.actual}>{item.priceActual} €</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Item;
