import React, { useState } from "react";
import cn from "classnames";
import Catalog from "./Catalog";
import Newsletter from "../../components/Newsletter";
import styles from "./Main.module.sass";
import Image from "../../components/Image";
import Form from "../../components/Form";

const gallery = [
    {
      src: "/images/content/support-pic-1.png",
      srcSet: "/images/content/support-pic-1.png",
      srcDark: "/images/content/support-pic-dark-1.png",
      srcSetDark: "/images/content/support-pic-dark-1.png",
    },
    {
      src: "/images/content/support-pic-2.png",
      srcSet: "/images/content/support-pic-2.png",
      srcDark: "/images/content/support-pic-dark-2.png",
      srcSetDark: "/images/content/support-pic-dark-2.png",
    },
    {
      src: "/images/content/support-pic-3.png",
      srcSet: "/images/content/support-pic-3.png",
      srcDark: "/images/content/support-pic-dark-3.png",
      srcSetDark: "/images/content/support-pic-dark-3.png",
    },
    {
      src: "/images/content/support-pic-4.png",
      srcSet: "/images/content/support-pic-4.png",
      srcDark: "/images/content/support-pic-dark-4.png",
      srcSetDark: "/images/content/support-pic-dark-4.png",
    },
  ];

const Support = () => {

    const [search, setSearch] = useState("");

    const handleSubmit = (e) => {};

    return (
        <>
            <div className={styles.section}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.gallery}>
                        {gallery.map((x, index) => (
                            <div className={styles.preview} key={index}>
                                <Image
                                    srcSet={`${x.srcSet} 2x`}
                                    srcSetDark={`${x.srcSetDark} 2x`}
                                    src={x.src}
                                    srcDark={x.srcDark}
                                    alt="Support"
                                />
                            </div>
                        ))}
                    </div>
                    <h2 className={cn("hero", styles.title)}>Nos Formations</h2>
                    <div className={styles.info}>
                        Des formations entièrement certifiés sur le renforement des compétences présentées en vidéos et éloborées par des spécialistes
                    </div>
                    <Form
                        className={styles.form}
                        big
                        value={search}
                        setValue={setSearch}
                        onSubmit={() => handleSubmit()}
                        placeholder="Rechercher une formation"
                        type="text"
                        name="search"
                        icon="search"
                    />
                </div>
            </div>
            <Catalog searchQuery={search} />
            <Newsletter />
        </>
    );
};

export default Support;
